// Конвертация строки вида (100.1,200)(200.1,120.2) в массив точек с округлением [100,200,200,120]
function stringToPoint(sValue, canvasDx, canvasDy, canvasZoom) {
    const r = [];
    sValue.split(")").forEach(element => {
        if (element.length > 0) {
            const el = element.substring(1, element.length);
            const idx = el.indexOf(",");
            let sX = el.substring(0, idx);
            if (sX.indexOf(".") !== -1) sX = sX.substring(0, sX.indexOf("."));
            let sY = el.substring(idx + 1);
            if (sY.indexOf(".") !== -1) sY = sY.substring(0, sY.indexOf("."));
            r.push(canvasDx + Math.round(Number(sX) * canvasZoom));
            r.push(canvasDy - Math.round(Number(sY) * canvasZoom));
        }
    });
    return r;
}

// Конвертация числа кода цвета Delphi в RGB hex
function delphiToRgb(iValue) {
    let color = iValue.toString(16);
    if (color.length < 6) color = "000000".substring(0, 6 - color.length) + color;
    if (color.length === 6) color = color.substring(4, 6) + color.substring(2, 4) + color.substring(0, 2);
    return `#${color}`;
}

// Получение позиции на канве по координате изделия - X
function getKanvaX(pos, { canvasDx, canvasZoom }) {
    return canvasDx + Math.round(pos * canvasZoom);
}

// Получение позиции на канве по координате изделия - Y
function getKanvaY(pos, { canvasDy, canvasZoom }) {
    return canvasDy - Math.round(pos * canvasZoom);
}

function getIzdObjects(izd, selectedObjectIds) {
    return selectedObjectIds.map(id => {
        return izd.cadElems.find(elem => {
            return elem.id === id
        })
    });
}

export { stringToPoint, delphiToRgb, getKanvaX, getKanvaY, getIzdObjects };

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Tree, Divider, Row, Col, message } from 'antd';

import { serverFetch } from '../../server';
import { tr } from '../../utils/translate';

const User = ({ ...props }) => {
    const user = useSelector((s) => s.user);
    const [form] = Form.useForm();

    const { id, onCancel, onSave } = props;

    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);

    const [basePermissions, setBasePermissions] = useState();
    const [treeData, setTreeData] = useState();
    const [checkedKeys, setCheckedKeys] = useState();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        form.setFieldsValue(state);

        if (state.permissions) {
            let keys = [];
            for (let i = 0; i < state.permissions.length; i++) {
                if (state.permissions[i]) keys.push(i + 1);
            }

            setCheckedKeys(keys);
        }
    }, [state]);

    useEffect(() => {
        if (state.permissions && basePermissions) {
            let keys = [];
            for (let i = 0; i < state.permissions.length; i++) {
                if (state.permissions[i] == 1) {
                    let permission = basePermissions.find((p) => p.id == i + 1);
                    if (permission) {
                        keys.push(permission.id);
                    }
                }
            }

            setCheckedKeys(keys);
        }
    }, [state, basePermissions]);

    const getData = () => {
        if (id) {
            setLoading(true);

            serverFetch(`usermanagement/${id}`, { method: 'GET' }, user)
                .then((data) => {
                    let permissions = data.permissions.split('');

                    if (permissions.length != 255) {
                        for (let index = permissions.length; index < 255; index++) {
                            permissions.push(1);
                        }
                    }

                    data.permissions = permissions;

                    setState(data);

                    setLoading(false);
                })
                .catch((e) => {
                    e.UserMessage && message.error(e.UserMessage);
                });
        } else {
            let data = { password: '', permissions: [] };
            for (let index = 0; index < 255; index++) {
                data.permissions.push(1);
            }

            setState(data);
        }

        serverFetch(`usermanagement/permissions`, { method: 'GET' }, user)
            .then((data) => {
                let tree = [];

                setBasePermissions(data.filter((d) => d.root != 0));

                let roots = data.filter((d) => d.root == 0);

                roots.map((r) => {
                    let children = [];
                    data.filter((c) => c.root == r.id).map((e) => {
                        children.push({ title: e.name, key: e.id });
                    });

                    tree.push({
                        title: r.name,
                        key: r.id,
                        children: children,
                    });
                });

                setTreeData(tree);

                setLoading(false);
            })
            .catch((e) => {
                message.error(
                    <>
                        {tr('Ошибка получения прав пользователя')}
                        <p>- {e.UserMessage}</p>
                    </>
                );

                setLoading(false);
            });
    };

    const save = () => {
        form.validateFields().then(() => {
            setLoading(true);

            let permissions = [];
            for (let i = 0; i < state.permissions.length; i++) {
                let basePermission = basePermissions.find((b) => b.id == i + 1);
                if (basePermission) {
                    let key = checkedKeys.find((k) => k == basePermission.id);
                    permissions.push(key ? 1 : 0);
                } else {
                    permissions.push(state.permissions[i]);
                }
            }

            let data = { ...state, permissions: permissions.join('') };

            if (!id) {
                serverFetch(`usermanagement`, { method: 'POST', bodyData: data }, user)
                    .then(() => {
                        onSave();
                    })
                    .catch((e) => {
                        e.UserMessage && message.error(e.UserMessage);
                    });
            } else {
                serverFetch(`usermanagement`, { method: 'PUT', bodyData: data }, user)
                    .then(() => {
                        onSave();
                    })
                    .catch((e) => {
                        e.UserMessage && message.error(e.UserMessage);
                    });
            }
        });
    };

    const onCheck = (checkedKeysValue) => {
        if (checkedKeysValue <= 0) return;

        setCheckedKeys(checkedKeysValue);
    };

    const renderPermissions = () => {
        return <Tree checkable defaultExpandAll={true} onCheck={onCheck} checkedKeys={checkedKeys} treeData={treeData} />;
    };

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={id ? tr('Изменить пользователя') : tr('Добавить пользователя')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => save()}
            cancelButtonProps={{ disabled: loading }}
            okButtonProps={{ disabled: loading }}
            width={800}
        >
            {state && (
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
                    <Form.Item
                        initialValue={state.userName}
                        label={tr('Имя пользователя')}
                        name='userName'
                        rules={[{ required: true, message: 'Пожалуйста введите имя пользователя' }]}
                    >
                        <Input
                            onChange={(data) => {
                                setState({ ...state, userName: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        initialValue={state.password}
                        label={tr('Пароль')}
                        name='password'
                        rules={[{ required: !id, message: 'Пожалуйста введите пароль' }]}
                    >
                        <Input.Password
                            placeholder={id && 'Сменить пароль'}
                            onChange={(data) => {
                                setState({ ...state, password: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item initialValue={state.fullName} label={tr('ФИО')} name='fullName'>
                        <Input
                            onChange={(data) => {
                                setState({ ...state, fullName: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item initialValue={state.position} label={tr('Должность')} name='position'>
                        <Input
                            onChange={(data) => {
                                setState({ ...state, position: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Divider orientation='center' plain>
                        Список прав
                    </Divider>

                    {treeData && renderPermissions()}
                </Form>
            )}
        </Modal>
    );
};

export default User;

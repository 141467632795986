import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { Spin, Button, Menu, Dropdown, Select, Space, Form, message } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import Products from './order-products';
import { orderLoaded } from '../../store/actions';
import OrderAksList from './order-aks-list';
import { tr } from '../../utils/translate';
import { toFinanceString } from '../../extensions/utils';
import { serverFetch } from '../../server';

const Order = () => {
    const { Option } = Select;
    const { orderGuid } = useParams();
    const d = useDispatch();
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const user = useSelector((s) => s.user);
    const order = useSelector((s) => s.order);
    const [refreshRequired, setRefreshRequired] = useState(false);

    useEffect(() => {
        getData();
        getReports();
    }, []);

    useEffect(() => {
        if (refreshRequired) {
            window.location.reload();
        }
    }, [refreshRequired]);

    useEffect(() => {
        if (order && order.statuses) {
            getStatuses();
        }
    }, [order]);

    const getData = () => {
        setLoading(true);

        if (orderGuid) {
            serverFetch(`orders/order/${orderGuid}`, { method: 'GET' }, user)
                .then((data) => {
                    d(orderLoaded(data));
                    setLoading(false);
                })
                .catch((e) => {
                    e.UserMessage && message.error(e.UserMessage);
                });
        } else {
            d(orderLoaded({ id: 0, orderGuid: '', docdate: new Date() }));
            setLoading(false);
        }
    };

    const getStatuses = () => {
        serverFetch(`orders/statuses`, { method: 'GET' }, user)
            .then((data) => {
                let buff = data.filter((s) => {
                    let status = order.statuses.find((e) => e == s.id);
                    if (status) return s;
                });

                setStatuses(buff);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const getReports = () => {
        serverFetch(`repository/reports/`, { method: 'GET' }, user)
            .then((reports) => {
                setReports(reports);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    if (!order)
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spin size='large' tip={tr('Пожалуйста, подождите...')} />
            </div>
        );

    let izds = [];
    izds.push({ id: 0, name: '' });
    order.orderIzds.map((izd) => izds.push({ id: izd.idStr, name: izd.ordno }));

    function handlePrintMenuClick(e) {
        let report = reports.find((r) => r.id == e.key);
        if (!report) return;

        setLoading(true);

        serverFetch(`orders/report/${order.orderGuid}/${report.id}`, { method: 'GET', blob: true }, user)
            .then((data) => {
                const file = new Blob([data], { type: 'application/pdf' });

                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;

                //FileSaver.saveAs(blob, report.name + '.pdf');
                setLoading(false);
            })
            .catch((e) => {
                message.error(tr('Ошибка печати'));
                setLoading(false);
            });
    }

    const printMenu = (
        <Menu onClick={handlePrintMenuClick}>
            {reports.map((r) => {
                return <Menu.Item key={r.id}>{r.name}</Menu.Item>;
            })}
        </Menu>
    );

    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spin size='large' tip={tr('Пожалуйста, подождите...')} />
                </div>
            ) : (
                <>
                    <div className='order-header'>
                        <h2>
                            {tr('Заказ')} {order.depno} / {order.docnum} {tr('от')} {moment(order.docdate).format('DD.MM.YYYY')}
                        </h2>
                        <Space>
                            <Dropdown disabled={reports.length < 1 || !order} overlay={printMenu}>
                                <Button icon={<PrinterOutlined />} style={{ marginLeft: 20 }}>
                                    {tr('Печать')}
                                </Button>
                            </Dropdown>
                            <div style={{ marginLeft: 10 }}>{tr('Статус')}</div>
                            <Select
                                disabled={order.isReadOnly}
                                placeholder='Статус'
                                defaultValue={order.status}
                                onChange={(value) => {
                                    let status = statuses.find((e) => e.name == value);
                                    setLoading(true);

                                    let data = { orderGuid: order.orderGuid, statusId: status.id };

                                    serverFetch(`orders/setorderstatus`, { method: 'POST', bodyData: data }, user)
                                        .then((data) => {
                                            setLoading(false);
                                            setRefreshRequired(true);
                                        })
                                        .catch((e) => {
                                            e.UserMessage && message.error(e.UserMessage);
                                            setLoading(false);
                                        });
                                }}
                                style={{ width: 250 }}
                            >
                                {statuses.map((s) => {
                                    return (
                                        <Option key={s.name} value={s.name}>
                                            {s.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Space>

                        <h2 style={{ marginLeft: 'auto' }}>
                            {tr('Итого')}: <span style={{ marginRight: 20 }}>{toFinanceString(order.syma)}</span>
                            {tr('Оплачено')}: <span style={{ marginRight: 20, color: 'green' }}>{toFinanceString(order.plat)}</span>
                            {tr('Долг')}: <span style={{ marginRight: 20, color: 'red' }}>{toFinanceString(order.dolg)}</span>
                        </h2>
                    </div>

                    <hr />

                    <Products
                        order={order}
                        user={user}
                        onRefresh={() => {
                            setRefreshRequired(true);
                        }}
                    />

                    <div style={{ marginTop: 20 }}>
                        <OrderAksList order={order} onSave={() => setRefreshRequired(true)} user={user} izds={izds} />
                    </div>
                </>
            )}
        </>
    );
};

export default Order;

import './custom.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { CookiesProvider } from 'react-cookie';
import configureStore from './store/store';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const { store, persistor } = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={baseUrl}>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    rootElement
);

import React from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import moment from 'moment';

function getCurrentDate() {
    return moment().startOf('day');
}

function getDaysFromMs(ms) {
    return Math.floor(ms / 1000 / 60 / 60 / 24) + 1;
}

function utilsGetDateSession(ValueName, DefaultValue) {
    let date = getCurrentDate();
    let QtySec = sessionStorage.getItem(ValueName);
    if (QtySec != null) date.setTime(QtySec);
    return date;
}

function utilsSetDateSession(ValueName, Value) {
    let QtySec = Value.getTime();
    sessionStorage.setItem(ValueName, QtySec);
}

function utilsGetDateLocalStorage(name, DefaultValue) {
    let ms = localStorage.getItem(name);
    if (ms) moment.unix(ms / 1000);

    return getCurrentDate();
}

function utilsSetDateLocalStorage(name, value) {
    console.log(name, value);
    let date = moment(value, 'DD.MM.YYYY').valueOf();
    localStorage.setItem(name, date);
}

function utilsCreateForm() {
    //this.setLocale(e.value);
    //console.log("Установка локали");
    //DevExpress.localization.loadMessages();
    //DevExpress.localization.locale("ru");
}

function getItemByIdOrNull(data, id) {
    if (data === null) return null;
    if (id === null) return null;
    const res = data.find((e) => e.id === id);
    if (res === undefined) return null;
    return res;
}

function getItemIdOrNull(item) {
    if (item === undefined || item === null) return null;
    return item.id;
}


function formsAddTextBoxField(fieldTitle, propertyValue, propertyName, forma) {
    return (
        <div className='dx-field'>
            <div className='dx-field-label'>{fieldTitle}</div>
            <div className='dx-field-value'>
                {propertyName === undefined ? (
                    <TextBox defaultValue={propertyValue} readOnly={true} />
                ) : (
                    <TextBox
                        defaultValue={propertyValue}
                        onValueChanged={(e) => {
                            const p = propertyName.indexOf('.');
                            if (p === -1) forma.setState({ [propertyName]: e.value });
                            else {
                                const objName = propertyName.slice(0, p);
                                const propName = propertyName.slice(p + 1);
                                forma.setState((prevState) => {
                                    let xxx = Object.assign({}, prevState[objName], { [propName]: e.value });
                                    return { [objName]: xxx };
                                });
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
}

function formsAddTextBoxFieldNumber(fieldTitle, propertyValue, propertyName, forma) {
    return (
        <div className='dx-field'>
            <div className='dx-field-label'>{fieldTitle}</div>
            <div className='dx-field-value'>
                {propertyName === undefined ? (
                    <TextBox defaultValue={propertyValue} readOnly={true} />
                ) : (
                    <TextBox
                        defaultValue={propertyValue}
                        onValueChanged={(e) => {
                            const p = propertyName.indexOf('.');
                            if (p === -1) forma.setState({ [propertyName]: e.value });
                            else {
                                const objName = propertyName.slice(0, p);
                                const propName = propertyName.slice(p + 1);
                                forma.setState((prevState) => {
                                    let xxx = Object.assign({}, prevState[objName], { [propName]: Number(e.value) });
                                    return { [objName]: Number(xxx) };
                                });
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
}

// Выбор свойства
function chooseSelectedValue(id, onSelect, items, propName) {
    console.log('chooseSelectedValue', id, propName);
    onSelect({
        [propName]: items.find((e) => e.id === id),
    });
}


export {
    getDaysFromMs,
    utilsGetDateSession,
    utilsSetDateSession,
    utilsCreateForm,
    getItemByIdOrNull,
    getItemIdOrNull,
    chooseSelectedValue,
    formsAddTextBoxField,
    formsAddTextBoxFieldNumber,
    utilsGetDateLocalStorage,
    utilsSetDateLocalStorage,
};

import React, { useState, useEffect } from 'react';

import { Spin, Table, Space, Button, message } from 'antd';

import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import OrderAks from './order-aks';
import { serverFetch } from '../../server';

import { tr } from '../../utils';
import { toFinanceString } from '../../extensions/utils';
import './order-aks-list.css';

const OrderAksList = ({ order, onSave, user, izds }) => {
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [selectedAksesIds, setSelectedAksesIds] = useState([]);
    const [currentAkses, setCurrentAkses] = useState();

    useEffect(() => {}, []);

    const addItem = () => {
        setCurrentAkses();
        setEditMode(true);
    };

    const deleteItem = () => {
        if (window.confirm(tr(`Удалить?`))) {
            setLoading(true);

            serverFetch(`orders/aksdel/${order.orderGuid}/${currentAkses.elem}/${currentAkses.detal}`, { method: 'DELETE' }, user)
                .then((data) => {
                    setLoading(false);
                    onSave();
                })
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error(e.UserMessage);
                });
        }
    };

    const save = () => {
        onSave();
        setEditMode({ editing: false });
    };

    if (order.orderIzds === undefined) return null;
    //if (loading) return (<p><em>Загрузка аксессуары...</em></p>);

    let total = 0;
    if (order && order.orderAkses) order.orderAkses.map((s) => (total = total + s.syma));

    const renderToolbar = () => {
        return (
            <Space className='toolbal'>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                        addItem();
                    }}
                >
                    {tr('Добавить')}
                </Button>
                <Button
                    disabled={!currentAkses}
                    icon={<EditOutlined />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    {tr('Изменить')}
                </Button>
                <Button
                    disabled={!currentAkses}
                    type='primary'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        deleteItem();
                    }}
                >
                    {tr('Удалить')}
                </Button>
            </Space>
        );
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedAksesIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let item = order.orderAkses.find((o) => o.id == selectedRowKeys[0]);
            setCurrentAkses(item);
        } else {
            setCurrentAkses();
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedAksesIds,
        onChange: onSelectChange,
        type: 'radio',
    };

    const renderTable = (orders) => {
        const columns = [
            {
                title: '#',
                dataIndex: 'id',
                width: 50,
            },
            {
                title: 'Изделие',
                dataIndex: 'ordno',
                width: 50,
                render: (_, record) => {
                    let izd = order.orderIzds.find((i) => i.idStr == record.idStr);
                    return <>{izd ? izd.ordno : ''}</>;
                },
            },
            {
                title: tr('Наименование'),
                dataIndex: 'matName',
                width: 120,
            },

            {
                title: tr('Цвет'),
                dataIndex: 'colorName',
                width: 120,
            },
            {
                title: tr('Длина'),
                dataIndex: 'w',
                width: 120,
            },
            {
                title: tr('Ширина'),
                dataIndex: 'h',
                width: 120,
            },
            {
                title: tr('Кол.'),
                dataIndex: 'qty',
                width: 120,
            },
            {
                title: tr('Сумма'),
                dataIndex: 'syma',
                width: 120,
            },
            {
                title: 'CAD',
                dataIndex: 'cad',
                width: 120,
            } /*  {
                title: 'idStr',
                dataIndex: 'cad',
                width: 120,
            }, */,
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={orders}
                loading={loading}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id]);
                        },
                    };
                }}
                scroll={{ y: 'calc(100vh - 215px)', x: 'calc(100vw - 40px)' }}
                pagination={{
                    showSizeChanger: true,
                    current: 1,
                    defaultPageSize: 20,
                }}
            />
        );
    };

    return (
        <>
            {loading && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spin size='large' tip={tr('Пожалуйста, подождите...')} />
                </div>
            )}
            <div className='aks-header'>
                <h2>{tr('Аксессуары и работы')}</h2>
                <h2>
                    {tr('Итого')}: <span style={{ marginRight: 20 }}>{toFinanceString(total)}</span>
                </h2>
            </div>
            {!order.isReadOnly && renderToolbar()}
            {renderTable(order.orderAkses)}
            {editMode && (
                <OrderAks
                    order={order}
                    user={user}
                    selectedAks={currentAkses}
                    izds={izds}
                    onCancel={() => {
                        setEditMode(false);
                    }}
                    onSave={save}
                    u
                />
            )}
        </>
    );
};

export default OrderAksList;

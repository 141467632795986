function format(template, args) {
    if (args === undefined) return template;
    let res = '';
    while (template !== '') {
        const pos = template.indexOf('{');
        if (pos === -1) {
            res = res + template;
            template = '';
        } else {
            res = res + template.slice(0, pos);
            template = template.slice(pos + 1);
            const pos2 = template.indexOf('}');
            if (pos2 === -1) {
                res = res + template;
                template = '';
            } else {
                res = res + args[template.slice(0, pos2)];
                template = template.slice(pos2 + 1);
            }
        }
    }
    return res;
}

export function trSetLocale(locale) {}

export function tr(string, args) {
    const locale = process.env.REACT_APP_LANGUAGE;

    const jsonData = localStorage.getItem('localization');
    let strings = JSON.parse(jsonData) || [];

    const s = strings.find((e) => e.ru === string);
    let translatedString = string;

    if (locale !== 'ru') {
        if (s !== undefined) translatedString = s[locale];
        else console.log('Не переведено', string);
    }

    return format(translatedString, args);
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import moment from 'moment';

import { Typography, Table, Space, Button, Menu, Dropdown, DatePicker, Drawer, Row, Col, Form, Switch, Tooltip, message } from 'antd';

import {
    CheckOutlined,
    PlusOutlined,
    AppstoreAddOutlined,
    EditOutlined,
    FolderOpenOutlined,
    PrinterOutlined,
    ReloadOutlined,
    SettingOutlined,
    SaveFilled,
} from '@ant-design/icons';

import OrderHead from './order-head';
import PackageSelection from '../package/package-selection';
import * as FileSaver from 'file-saver';
import { delphiToRgb } from '../cad/cad-funcs';
import { tr } from '../../utils';

import { permission, hasPermission } from '../../extensions/helper';

import { serverFetch } from '../../server';

import '../shared.css';
import './orders.css';

const settingContext = 'Orders';
const settingType = 'TableColumns';

const Orders = () => {
    const { Text } = Typography;

    const user = useSelector((s) => s.user);
    const history = useHistory();

    const [settingsForm] = Form.useForm();

    const [orders, setOrders] = useState([]);
    const [reports, setReports] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [currentOrder, setCurrentOrder] = useState();
    const [refreshRequired, setRefreshRequired] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [packMode, setPackMode] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [columnSettings, setColumnSettings] = useState();

    const [filter, setFilter] = useState({});

    useEffect(() => {
        let storedFilter = localStorage.getItem('ordersFilter');

        if (storedFilter) {
            storedFilter = JSON.parse(storedFilter);
            setFilter({
                ...storedFilter,
                dateFrom: storedFilter.dateFrom && moment(storedFilter.dateFrom),
                dateTo: storedFilter.dateTo && moment(storedFilter.dateTo),
            });
        } else {
            setFilter({
                dateFrom: moment().add(-1, 'days'),
                dateTo: moment(),
            });
        }

        getStatuses();
        getReports();
    }, []);

    useEffect(() => {
        if (filter.dateFrom && filter.dateTo && refreshRequired) {
            setRefreshRequired(false);
            getData();
        }

        localStorage.setItem('ordersFilter', JSON.stringify(filter));
    }, [filter, refreshRequired]);

    const getData = () => {
        setLoading(true);

        let queryParams = {
            dateFrom: filter.dateFrom && filter.dateFrom.format(),
            dateTo: filter.dateTo && filter.dateTo.format(),
        };

        serverFetch('orders', { method: 'GET', queryParams: queryParams }, user)
            .then((data) => {
                setOrders(data);
                setLoading(false);
                setEditMode(false);
            })
            .catch((e) => {
                message.error(tr('Ошибка получения заказов'));
                setLoading(false);
            });

        let settingsQueryParams = {
            settingType: settingType,
            context: settingContext,
        };

        serverFetch('usersettings', { method: 'GET', queryParams: settingsQueryParams }, user)
            .then((data) => {
                let settings = [];
                if (data) {
                    settings = JSON.parse(data.settingValue);
                } else {
                    let columns = getColumns();

                    columns.map((c) => {
                        settings.push({ visible: true, column: c.dataIndex, title: c.title });
                    });
                }

                setColumnSettings(settings);
            })
            .catch((e) => {
                message.error(tr('Ошибка получения настроек'));
                setLoading(false);
            });
    };

    const getStatuses = () => {
        serverFetch(`orders/statuses`, { method: 'GET' }, user)
            .then((statuses) => setStatuses(statuses))
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const getReports = () => {
        serverFetch(`repository/reports`, { method: 'GET' }, user)
            .then((reports) => {
                setReports(reports);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    function handlePrintMenuClick(e) {
        let report = reports.find((r) => r.id == e.key);
        if (!report) return;

        setLoading(true);

        serverFetch(`orders/report/${currentOrder.orderGuid}/${report.id}`, { method: 'GET', blob: true }, user)
            .then((data) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                //FileSaver.saveAs(blob, report.name + '.pdf');
                setLoading(false);
            })
            .catch((e) => {
                message.error(
                    <>
                        {tr('Ошибка печати')}
                        <p>- {e.UserMessage}</p>
                    </>
                );
                setLoading(false);
            });
    }

    const renderToolbar = () => {
        const printMenu = (
            <Menu onClick={handlePrintMenuClick}>
                {reports.map((r) => {
                    return <Menu.Item key={r.id}>{r.name}</Menu.Item>;
                })}
            </Menu>
        );

        return (
            <Row>
                <Col span={23} align='left'>
                    <Space className='toolbal'>
                        <Text>{tr('Заказы за период')}</Text>
                        <DatePicker
                            format='DD.MM.YYYY'
                            allowClear={false}
                            value={filter.dateFrom}
                            onChange={(dateX, date) => {
                                var dateFrom =
                                    date &&
                                    moment(date, 'DD.MM.YYYY').utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

                                setFilter({ ...filter, dateFrom: dateFrom });

                                setRefreshRequired(true);
                            }}
                        />
                        <Text>-</Text>
                        <DatePicker
                            format='DD.MM.YYYY'
                            allowClear={false}
                            value={filter.dateTo}
                            onChange={(dateX, date) => {
                                var dateTo =
                                    date &&
                                    moment(date, 'DD.MM.YYYY').utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

                                setFilter({ ...filter, dateTo: dateTo });

                                setRefreshRequired(true);
                            }}
                        />

                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                setRefreshRequired(true);
                            }}
                        >
                            {tr('Обновить')}
                        </Button>
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setSelectedOrderIds([]);
                                setCurrentOrder({});
                                setEditMode(true);
                            }}
                        >
                            {tr('Добавить')}
                        </Button>
                        <Button
                            disabled={selectedOrderIds.length < 1 || !hasPermission(user.permissions, permission.AddOrderToPackage)}
                            type='primary'
                            icon={<AppstoreAddOutlined />}
                            onClick={() => {
                                setPackMode(true);
                            }}
                        >
                            {tr('В пакет')}
                        </Button>
                        <Button
                            disabled={!currentOrder}
                            icon={<EditOutlined />}
                            onClick={() => {
                                setEditMode(true);
                            }}
                        >
                            {tr('Изменить')}
                        </Button>
                        <Button
                            disabled={!currentOrder}
                            icon={<FolderOpenOutlined />}
                            onClick={() => {
                                history.push(`/order/edit/${currentOrder.orderGuid}`);
                            }}
                        >
                            {tr('Открыть')}
                        </Button>

                        <Dropdown disabled={reports.length < 1 || !currentOrder} overlay={printMenu}>
                            <Button icon={<PrinterOutlined />}>{tr('Печать')}</Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={1} align='right'>
                    <Button shape='circle' icon={<SettingOutlined />} onClick={() => setOpenSettings(true)} />
                </Col>
            </Row>
        );
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedOrderIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let order = orders.find((o) => o.orderGuid == selectedRowKeys[0]);
            setCurrentOrder(order);
        } else {
            setCurrentOrder();
        }
    };

    const rowSelection = {
        selectedRowKeys: selectedOrderIds,
        onChange: onSelectChange,
    };

    const getColumns = () => {
        return [
            {
                title: tr('Отдел'),
                dataIndex: 'depno',
                width: 60,
            },
            {
                title: 'N',
                dataIndex: 'docnum',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: (a, b) => a.docnum - b.docnum,
                render: (_, record) => {
                    return <a href={`/order/edit/${record.orderGuid}`}>{record.docnum}</a>;
                },
                width: 60,
            },
            {
                title: tr('Дата'),
                dataIndex: 'docdate',
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.docdate).unix() - moment(b.docdate).unix(),
                render: (_, record) => {
                    return <>{moment(record.docdate).format('DD.MM.YYYY')} </>;
                },
                width: 70,
            },
            {
                title: tr('Клиент'),
                dataIndex: 'cName',
                width: 120,
            },
            {
                title: tr('Адрес'),
                dataIndex: 'cAddress',
                width: 120,
            },
            {
                title: tr('Телефон'),
                dataIndex: 'cPhone',
                width: 80,
            },
            {
                title: tr('Комментарий'),
                dataIndex: 'comment',
                width: 250,
            },
            {
                title: tr('Сумма'),
                dataIndex: 'syma',
                width: 80,
            },
            {
                title: tr('Оплата'),
                dataIndex: 'plat',
                width: 80,
            },
            {
                title: tr('Долг'),
                dataIndex: 'dolg',
                width: 80,
            },
            {
                title: tr('Готов'),
                dataIndex: 'closed',
                render: (_, record) => {
                    return <>{record.closed && <CheckOutlined />} </>;
                },
                align: 'center',
                width: 80,
            },
            {
                title: tr('Статус'),
                dataIndex: 'status',
                width: 120,

                render: (_, record) => {
                    return (
                        <>
                            <span className='dotStatus' style={{ backgroundColor: record.statusColor }}></span>
                            <span style={{ marginLeft: 10 }}>{record.status}</span>
                        </>
                    );
                },
            },
            {
                title: tr('Сотрудник'),
                dataIndex: 'sotrName',
                width: 150,
            },
        ];
    };

    const renderTable = () => {
        let columns = [];
        getColumns().map((c) => {
            let setting = columnSettings.find((s) => s.column == c.dataIndex);
            if (setting && setting.visible) columns.push(c);
        });

        return (
            <Table
                rowKey='orderGuid' // id
                size='small'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={orders}
                loading={loading}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.orderGuid]); // id
                        },
                    };
                }}
                scroll={{ y: 'calc(100vh - 251px)', x: 'calc(100vw - 40px)' }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
            />
        );
    };

    const divOrderHead = editMode ? (
        <OrderHead
            orderGuid={currentOrder && currentOrder.orderGuid}
            onCancel={() => setEditMode(false)}
            onSave={() => {
                let now = new moment();
                if (now > filter.dateTo) {
                    now = moment(now, 'DD.MM.YYYY').utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                    setFilter({ ...filter, dateTo: now });
                }
                setRefreshRequired(true);
            }}
        />
    ) : null;

    const divPackHead = packMode ? <PackageSelection orders={selectedOrderIds} onClose={() => setPackMode(false)} /> : null;

    const onClose = () => {
        setOpenSettings(false);
    };

    const renderTableSettings = () => {
        return (
            <Drawer
                title='Настройка колонок'
                width={300}
                onClose={onClose}
                visible={openSettings}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Tooltip title='Сохранить настройки'>
                            <Button
                                icon={<SaveFilled />}
                                size='large'
                                type='primary'
                                onClick={() => {
                                    Object.entries(settingsForm.getFieldsValue()).map((e) => {
                                        let setting = columnSettings.find((s) => s.column == e[0]);
                                        if (setting) {
                                            setting.visible = e[1];
                                        }
                                    });

                                    let data = {
                                        settingType: settingType,
                                        context: settingContext,
                                        settingValue: JSON.stringify(columnSettings),
                                    };

                                    serverFetch(`usersettings`, { method: 'POST', bodyData: data }, user)
                                        .then((data) => {
                                            setLoading(false);
                                        })
                                        .catch((e) => {
                                            e.UserMessage && message.error(e.UserMessage);
                                            setLoading(false);
                                        });
                                    onClose();
                                }}
                            ></Button>
                        </Tooltip>
                    </Space>
                }
            >
                <Form layout='horizontal' labelCol={{ span: 8 }} wrapperCol={{ span: 4 }} form={settingsForm}>
                    {columnSettings.map((s) => {
                        return (
                            <Form.Item initialValue={s.visible} key={s.column} label={s.title} name={s.column} valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                        );
                    })}
                </Form>
            </Drawer>
        );
    };

    return (
        <>
            {renderToolbar()}
            {columnSettings && renderTable()}
            {columnSettings && renderTableSettings()}
            {divPackHead}
            {divOrderHead}
        </>
    );
};

export default Orders;

import React, { useState, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { Rnd } from 'react-rnd';

import './cad-image.css';

export const CadImage = (props) => {
    const [cookies, setCookie] = useCookies(['cadImage']);

    const [rndState, setRndState] = useState();
    const [imgSize, setImgSize] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);

    useEffect(() => {
        if (cookies.cadImage === undefined || cookies.cadImage === 'undefined') {
            setRndState({ x: 100, y: 0, width: 200, height: 200 });
            setImgSize({ width: 200, height: 200 });
        } else {
            setRndState(cookies.cadImage);

            let width =
                typeof cookies.cadImage.width == 'number'
                    ? cookies.cadImage.width
                    : parseInt(cookies.cadImage.width.substring(0, cookies.cadImage.width.length - 2));

            let height =
                typeof cookies.cadImage.height == 'number'
                    ? cookies.cadImage.height
                    : parseInt(cookies.cadImage.height.substring(0, cookies.cadImage.height.length - 2));

            setImgSize({ width: width, height: height });
        }
    }, []);

    useEffect(() => {
        setCookie('cadImage', rndState);
    }, [rndState]);

    return rndState ? (
        <Rnd
            className='rnd-control'
            style={isResizing && { boxShadow: 'none', backgroundColor: 'transparent', border: 'none' }}
            size={{ width: rndState.width, height: rndState.height }}
            position={{ x: rndState.x, y: rndState.y }}
            onDrag={(e, d) => {
                setIsDragging(true);
            }}
            onDragStop={(e, d) => {
                setRndState({ ...rndState, x: d.x, y: d.y });
                setIsDragging(false);
            }}
            onResizeStart={() => {
                setIsResizing(true);
            }}
            onResize={(e, direction, ref, delta, position) => {
                let size = delta.width !== 0 ? ref.style.width : ref.style.height;

                setRndState({
                    ...rndState,
                    width: size,
                    height: size,
                    ...position,
                });

                let widthStr = size.substring(0, size.length - 2);
                let heightStr = size.substring(0, size.length - 2);
                setImgSize({ width: parseInt(widthStr), height: parseInt(heightStr) });
            }}
            onResizeStop={() => {
                setIsResizing(false);
            }}
        >
            {!isDragging && <img src={props.picture} alt='scheme' width={imgSize.width - 10} height={imgSize.height - 10} />}
        </Rnd>
    ) : (
        ''
    );
};

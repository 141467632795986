import React from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, message } from 'antd';

import { tr } from '../../../utils';

import { serverFetch } from '../../../server';

export const CadCmdSplitPopup = ({ vidOper, onCancel, onChange, onOk }) => {
    const elemSize = useSelector((s) => s.elemSize);

    if (vidOper !== 'SPLIT_VERT' && vidOper !== 'SPLIT_HOR') return null;

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={tr('Вставка импоста')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
            width={300}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item initialValue={elemSize.xValue} label={tr('Координата')} name='xValue'>
                    <Input
                        onChange={(data) => onChange('xValue', data.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) onOk();
                        }}
                        placeholder={tr('Координата')}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const CadCmdSplitStartOper = (vidOper, selectedObject, setElemSize) => {
    if (vidOper === 'SPLIT_VERT')
        setElemSize({
            xValue: Math.round((selectedObject.minX + selectedObject.maxX) / 2),
            selectedObject,
        });
    else if (vidOper === 'SPLIT_HOR')
        setElemSize({
            xValue: Math.round((selectedObject.minY + selectedObject.maxY) / 2),
            selectedObject,
        });
    else return false;
    return true;
};

export async function CadCmdSplitDoOper(selectedObject, vidOper, xValue, data, user, refreshCad) {
    if (vidOper === 'SPLIT_VERT') {
        data = { ...data, x1: xValue, x2: xValue, y1: selectedObject.minY, y2: selectedObject.maxY };
    } else if (vidOper === 'SPLIT_HOR') {
        data = { ...data, y1: xValue, y2: xValue, x1: selectedObject.minX, x2: selectedObject.maxX };
    }

    await serverFetch(`izd/opersplit`, { method: 'POST', bodyData: data }, user)
        .then((data) => {
            refreshCad && refreshCad();
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
}

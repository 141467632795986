import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Table, Modal, Space, Button, message } from 'antd';
import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons';

import { tr } from '../../utils/translate';
import { serverFetch } from '../../server';

const PackageDetails = ({ packageId, onClose }) => {
    const user = useSelector((s) => s.user);

    const [selectedOrderIds, setSelectedOrderIds] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentOrder, setCurrentOrder] = useState();
    const [refreshRequired, setRefreshRequired] = useState(true);

    useEffect(() => {
        if (refreshRequired) {
            setRefreshRequired(false);
            getData();
        }
    }, [refreshRequired]);

    const getData = () => {
        serverFetch(`packages/orders/${packageId}`, { method: 'GET' }, user)
            .then((data) => {
                setOrders(data);
                setLoading(false);
            })
            .catch((e) => {
                message.error(
                    <>
                        {tr('Ошибка получения заказов')}
                        <p>- {e.UserMessage}</p>
                    </>
                );

                setLoading(false);
            });
    };

    const renderToolbar = () => {
        return (
            <Space className='toolbal'>
                <Button
                    icon={<ReloadOutlined />}
                    onClick={() => {
                        setRefreshRequired(true);
                    }}
                >
                    {tr('Обновить')}
                </Button>
                <Button
                    disabled={!currentOrder}
                    type='primary'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        serverFetch(`packages/deleteorder/${packageId}/${currentOrder.orderGuid}`, { method: 'DELETE' }, user)
                            .then(() => {
                                setCurrentOrder(null);
                                setRefreshRequired(true);
                            })
                            .catch((e) => {
                                e.UserMessage && message.error(e.UserMessage);;
                            });
                    }}
                >
                    {tr('Удалить')}
                </Button>
            </Space>
        );
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedOrderIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let order = orders.find((o) => o.docnum == selectedRowKeys[0]);
            setCurrentOrder(order);
        } else {
            setCurrentOrder();
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedOrderIds,
        onChange: onSelectChange,
    };

    function renderTable() {
        const columns = [
            {
                title: tr('Дата'),
                dataIndex: 'docdate',
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.docDate).unix() - moment(b.docdate).unix(),
                render: (_, record) => {
                    return <>{moment(record.docDate).format('DD.MM.YYYY')} </>;
                },
                width: 120,
            },
            {
                title: tr('Отдел'),
                dataIndex: 'depno',
                width: 80,
            },
            {
                title: '№',
                dataIndex: 'docnum',
                width: 80,
            },
            {
                title: tr('Клиент'),
                dataIndex: 'cName',
                width: 120,
            },
            {
                title: tr('Сумма'),
                dataIndex: 'syma',
                width: 120,
            },
            {
                title: tr('Площадь'),
                dataIndex: 'sqrtIzd',
                width: 100,
            },
            {
                title: tr('Договор'),
                dataIndex: 'comment',
                width: 200,
            },
        ];
        return (
            <>
                <Table
                    rowKey='docnum'
                    size='small'
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={orders}
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onSelectChange([record.docnum]);
                            },
                        };
                    }}
                    scroll={{ y: 'calc(100vh - 514px)', x: 'calc(100vw - 40px)' }}
                    pagination={{
                        showSizeChanger: true,
                        current: 1,
                        defaultPageSize: 20,
                    }}
                />
            </>
        );
    }

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={tr('Пакет')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            cancelText={tr('Закрыть')}
            onCancel={() => onClose()}
            okButtonProps={{ style: { display: 'none' } }}
            width={1000}
        >
            {renderToolbar()}
            {renderTable()}
        </Modal>
    );
};

export default PackageDetails;

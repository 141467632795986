import React, { useState } from 'react';

import { Col, Form, Input, message } from 'antd';
import { MButton } from '../lib';
import './order-product.css';
import { serverFetch, apiBaseUrl } from '../../server';

import { tr } from '../../utils/translate';
import ProductModal from './order-product-modal';

const Product = ({ ...props }) => {
    const { product, order, user, onDelete, onRefresh } = props;

    const [openProduct, setOpenProduct] = useState(false);

    const cancel = () => {
        setOpenProduct(false);
    };

    const saveProduct = () => {
        setOpenProduct(false);
        onRefresh();
    };

    const btnDeleteConstruction = () => {
        if (window.confirm(tr('Удалить изделие') + ` ${product.idStr}?`)) {
            serverFetch(`orders/delconstruct/${order.orderGuid}/${product.idStr}`, { method: 'DELETE' }, user)
                .then((data) => {
                    onDelete();
                })
                .catch((e) => {
                    message.error(
                        <>
                            {tr('Ошибка удаления конструкции')}
                            <p>- {e.UserMessage}</p>
                        </>
                    );
                });
        }
    };

    const divError = product.calcError && product.calcError !== 0 ? 
        <div className='product-error'><strong>Ошибка {product.calcError}</strong></div> : 
        <img src={`${apiBaseUrl}/api/media/getimage/constr/${product.fileName}`} alt='scheme' />;

    return (
        <>
            <Col span={6}>
                <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
                    <Form.Item initialValue={product.ordno} label={tr('Изделие')} name='ordno'>
                        <Input disabled={true} style={{ width: '75%' }} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        {order.isReadOnly ? (
                            {divError}
                        ) : (
                            <a href={`cad/${order.orderGuid}/${product.idStr}`}>
                                {divError}
                            </a>
                        )}
                    </Form.Item>

                    {!order.isReadOnly && (
                        <Form.Item>
                            <div style={{ display: 'flex' }}>
                                <MButton
                                    type='edit'
                                    onClick={() => {
                                        setOpenProduct(true);
                                    }}
                                />
                                <MButton
                                    type='delete'
                                    onClick={() => {
                                        btnDeleteConstruction();
                                    }}
                                />
                                <MButton
                                    text={tr('Чертеж')}
                                    onClick={() => {
                                        document.location.href = `cad/${order.orderGuid}/${product.idStr}`;
                                    }}
                                />
                            </div>
                        </Form.Item>
                    )}
                </Form>
            </Col>
            <Col span={9}>
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item initialValue={product.sysName} label={tr('Система')} name='sysName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.colorName} label={tr('Цвет')} name='colorName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.qty} label={tr('Количество')} name='qty'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.syma} label={tr('Сумма')} name='syma'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.name} label={tr('Наименование')} name='name'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.comment} label={tr('Комментарий')} name='comment'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={9}>
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item initialValue={product.diffName} label={tr('Сложность')} name='diffName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.montName} label={tr('Монтаж')} name='montName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.vstName} label={tr('Вставка')} name='vstName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.zapolnName} label={tr('Заполнение')} name='zapolnName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.furnName} label={tr('Фурнитура')} name='furnName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>

                    <Form.Item initialValue={product.moskName} label={tr('М/сетка')} name='moskName'>
                        <Input disabled={true} style={{ color: 'black' }} />
                    </Form.Item>
                </Form>
            </Col>

            {openProduct && (
                <ProductModal
                    orderGuid={order.orderGuid}
                    idStr={product.idStr}
                    ordno={product.ordno}
                    onCancel={() => cancel()}
                    onSave={() => saveProduct()}
                    user={user}
                />
            )}
        </>
    );
};

export default Product;

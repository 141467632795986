import React, { useState, useEffect } from 'react';
import { Table, Select, Modal, Form, Input, Col, Row, message } from 'antd';
import './order.css';
import { getItemByIdOrNull, getItemIdOrNull } from '../../utils/utils';
import { tr } from '../../utils/translate';
import { serverFetch, apiBaseUrl } from '../../server';
import './order-product-modal.css';

const ProductModal = ({ orderGuid, idStr, onCancel, onSave, user, ordno }) => {
    const { Option } = Select;
    // Группа типовых конструкций
    const [construction, setConstruction] = useState();
    const [templateGroups, setTemplateGroups] = useState([]);
    const [currentGroup, setCurrentGroup] = useState();
    const [isEditMode] = useState(!!idStr);
    const [groupForm] = isEditMode ? [] : Form.useForm();
    const [configForm] = Form.useForm();
    const [loading, setLoading] = useState(true);
    // Типовая конструкция
    const [templates, setTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState();
    const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);
    const [systems, setSystems] = useState([]);
    const [zapolns, setZapolns] = useState([]);
    const [diffs, setDiffs] = useState([]);
    const [monts, setMonts] = useState([]);
    const [furns, setFurns] = useState([]);
    const [vsts, setVsts] = useState([]);
    const [paramNames, setParamNames] = useState([]);
    const [paramValues, setParamValues] = useState();
    const [selectedSysId, setSelectedSysId] = useState();
    const [selectedColor, setSelectedColor] = useState();
    const [selectedColorIn, setSelectedColorIn] = useState();
    const [selectedColorOut, setSelectedColorOut] = useState();
    const [selectedColorB, setSelectedColorB] = useState(); // Цвет основы
    const [selectedColorF, setSelectedColorF] = useState(); // Цвет фальца
    const [selectedZapoln, setSelectedZapoln] = useState();
    const [selectedMont, setSelectedMont] = useState();
    const [selectedFurn, setSelectedFurn] = useState();
    const [selectedVst, setSelectedVst] = useState();
    const [selectedDiff, setSelectedDiff] = useState();
    const [qty, setQty] = useState(1);
    const [comment, setComment] = useState();
    const [constName, setConstName] = useState();
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(1000);

    useEffect(() => {
        getDataTemplateGroups();
        if (isEditMode) {
            getConstruction();
            getAllSys();
        }
    }, []);

    useEffect(() => {
        if (templateGroups.length > 0 && !currentGroup) {
            chooseSelectedGroup(templateGroups[0].id);
        }
    }, [templateGroups]);

    useEffect(() => {
        if (isEditMode && construction) setSelectedVst(vsts.find((e) => e.id === construction.idVst));
    }, [vsts, construction]);

    useEffect(() => {
        if (isEditMode && construction) setSelectedMont(monts.find((e) => e.id === construction.idMont));
    }, [monts, construction]);

    useEffect(() => {
        if (isEditMode && construction) setSelectedFurn(furns.find((e) => e.id === construction.idFurn));
    }, [furns, construction]);

    useEffect(() => {
        if (isEditMode && construction) setSelectedDiff(diffs.find((e) => e.id === construction.idDiff));
    }, [diffs, construction]);

    useEffect(() => {
        if (isEditMode && construction) {
            let sys = getItemByIdOrNull(systems, construction.idSys);
            let color = sys ? sys.colorGroup.colors.find((e) => e.id === construction.idColor) : null;
            setSelectedColor(color);

            if (color) {
                color.colorIn && setSelectedColorIn(color.colorIn.colorCnt.find((e) => e.id === construction.idColorIn));
                color.colorOut && setSelectedColorOut(color.colorOut.colorCnt.find((e) => e.id === construction.idColorOut));
                color.colorBase && setSelectedColorB(color.colorBase.colorCnt.find((e) => e.id === construction.idColorB));
                color.colorF && setSelectedColorF(color.colorF.colorCnt.find((e) => e.id === construction.idColorF));
            }
        }
    }, [systems, construction]);

    useEffect(() => {
        const system = getItemByIdOrNull(systems, selectedSysId);
        const color = !selectedColor || !getItemByIdOrNull(system.colorGroup.colors, selectedColor.id) ? null : selectedColor;
        setSelectedColor(color);
    }, [selectedSysId]);

    useEffect(() => {
        async function initData() {
            if (isEditMode && construction) {
                await  chooseSelectedSys(construction.idSys)
                 .then((r) => {

                        setQty(construction.qty);
                        setComment(construction.comment);
                        setConstName(construction.constName);
                        setWidth(construction.w);
                        setHeight(construction.h);
        
                        let cadParams = [];
                        construction.cadParamValues.map((p) => {
                            cadParams['param' + p.idName] = p.idVal;
                        });
        
                        setParamValues(cadParams);
        
                        configForm.setFieldsValue({
                            qty: construction.qty,
                            comment: construction.comment,
                            constName: construction.constName,
                            width: construction.w,
                            height: construction.h,
                            selectedMont: construction.idMont || undefined,
                            selectedVst: construction.idVst || undefined,
                            selectedDiff: construction.idDiff || undefined,
                            selectedSysId: construction.idSys,
                            selectedColor: construction.idColor,
                            selectedColorIn: construction.idColorIn,
                            selectedColorOut: construction.idColorOut,
                            selectedColorB: construction.idColorB,
                            selectedColorF: construction.idColorF,
                            ...cadParams,
                        });

                    }
            );
        }
    };
    initData();
    }, [construction,systems]);


    useEffect(() => {
        if (groupForm && currentGroup) {
            groupForm.setFieldsValue({
                id: currentGroup.id,
            });

            setSystems(currentGroup.sys);
        }
    }, [currentGroup]);

    async function getConstruction() {
        setLoading(true);

        await serverFetch(`orders/construction/${orderGuid}/${idStr}`, { method: 'GET' }, user)
            .then((data) => {
                setLoading(false);
                setConstruction(data);
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    }
    async function getAllSys() {
        setLoading(true);
        await serverFetch(`repository/allsys`, { method: 'GET' }, user)
            .then((data) => {
                setLoading(false);
                setSystems(data);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
                setLoading(false);
            });
    }

    async function getDataTemplateGroups() {
        setLoading(true);
        await serverFetch(`repository/getstartdetails`, { method: 'GET' }, user)
            .then((data) => {
                const { templateGroups, diffs } = data;
                setTemplateGroups(templateGroups);
                setDiffs(diffs);
                setLoading(false);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
                setLoading(false);
            });
    }

    // Выбор группы типовых конструкций
    async function chooseSelectedGroup(id) {
        // Менять если не выбрана группа была и id не null и не совпадает со старой группой
        if (id && (!currentGroup || currentGroup.id !== id)) {
            const newSelectedGroup = templateGroups.find((e) => e.id === id);
            setCurrentGroup(newSelectedGroup);
            configForm.setFieldsValue({
                selectedSysId: undefined,
                width: 0,
                height: 0,
            });
            setLoading(true);
            serverFetch(`repository/gettemplates/${id}`, { method: 'GET' }, user)
                .then((data) => {
                    setLoading(false);
                    setTemplates(data);
                })
                .catch((e) => {
                    e.UserMessage && message.error(e.UserMessage);
                    setLoading(false);
                });
        }
    }

    // Выбор профильной системы
    async function chooseSelectedSys(id) {
        console.log(`chooseSelectedSys ${id}`);
        setLoading(true);        
        setSelectedSysId(id);
        await serverFetch(`repository/getsysdetails/${id}`, { method: 'GET' }, user)
            .then((data) => {
                const { zapolns, furns, vsts, monts, paramNames, paramValues } = data;
                setZapolns(zapolns);
                setMonts(monts);
                setFurns(furns);
                setVsts(vsts);
                setParamNames(paramNames);
                let x = {};
                paramValues.forEach((item) => {
                    x['param' + item.idName] = item.idValue;
                });
                setParamValues({ ...x });
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    }

    // Выбор типовой конструкции
    async function chooseTemplate(id) {
        console.log(`chooseTemplate ${id}`);
        console.log(systems);
        const selectedTemplate = templates.find((e) => e.id === id);
        console.log(selectedTemplate);
        const newSys =
            !selectedTemplate || !selectedTemplate.sprSys || !getItemByIdOrNull(systems, selectedTemplate.sprSys.id)
                ? null
                : selectedTemplate.sprSys;
        const newIdSys = newSys ? newSys.id : selectedSysId;
        if (newSys) await chooseSelectedSys(newIdSys);
        setCurrentTemplate(selectedTemplate);
        setWidth(selectedTemplate.w);
        setHeight(selectedTemplate.h);
        const newColorId = selectedTemplate.sprColor ? selectedTemplate.sprColor.id : null;
        setSelectedColor(selectedTemplate.sprColor);
        
        let cadParams = [];
        if (selectedTemplate)
            selectedTemplate.params2.map((p) => {
                cadParams['param' + p.idName] = p.idVal;
            });

        console.log(cadParams);
        setParamValues(cadParams);

        //configForm.setFieldValue('selectedSysId', newSys.id);
        configForm.setFieldsValue({
            width: selectedTemplate.w,
            height: selectedTemplate.h,
            selectedSysId: newIdSys,
            selectedColor: newColorId,
            ...cadParams
        });
        //chooseSelectedSys(selectedTemplate.sprSys.id);
        setSelectedColor(selectedTemplate.sprColor);
    }

    // Определяет является ли цветовая сторона палитрой
    function getColorSideIsPallete(selectedColor, colorSideName) {
        return selectedColor && selectedColor.vid === 1 && selectedColor[colorSideName] && selectedColor[colorSideName].vid === 3;
    }

    const save = async () => {
        function GetId(obj) {
            if (!obj) return 0;
            return obj.id;
        }
        let allParamsFilled = true;
        let params = [];
        console.log(paramValues);
        paramNames.forEach((pn) => {
            if (!paramValues['param' + pn.id]) {
                console.log("Параметр не заполнен", pn);
                allParamsFilled = false;
                return;
            }
            params.push({
                IdName: pn.id,
                IdVal: paramValues['param' + pn.id],
            });
        });
        if (!allParamsFilled) {
            message.warning('Пожалуйста укажите все параметры изделия');
            return;
        }
        const order = {
            orderGuid: orderGuid,
            idStr: idStr || 0,
            templateId: GetId(currentTemplate),
            qty: qty,
            constName: constName,
            comment: comment,
            w: width,
            h: height,
            diff: GetId(selectedDiff),
            sys: selectedSysId,
            zapoln: GetId(selectedZapoln),
            color: GetId(selectedColor),
            colorIn: GetId(selectedColorIn),
            colorOut: GetId(selectedColorOut),
            colorB: GetId(selectedColorB),
            colorF: GetId(selectedColorF),
            mont: GetId(selectedMont),
            vst: GetId(selectedVst),
            furn: GetId(selectedFurn),
            sParam: JSON.stringify(params),
        };

        setLoading(true);

        serverFetch(`orders/addconstruct`, { method: 'POST', bodyData: order }, user)
            .then((data) => {
                setLoading(false);
                onSave();
            })
            .catch((e) => {
                setLoading(false);

                message.error(
                    <>
                        {tr('Ошибка сохранения конструкции')}
                        <p>- {e.UserMessage}</p>
                    </>
                );
            });
    };

    function renderCombobox(dataSource, title, placeholder, selectedValue, onChoose, propName) {
        return (
            <Form.Item initialValue={selectedValue} label={title} name={propName}>
                <Select placeholder={placeholder} onChange={(value) => onChoose(value, dataSource, propName)}>
                    {dataSource.map((d) => {
                        return (
                            <Option key={`${propName}${d.id}`} value={d.id}>
                                {d.name}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    }

    const onSelectTemplateChange = (selectedRowKeys) => {
        setSelectedTemplateIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let template = templates.find((o) => o.id == selectedRowKeys[0]);
            chooseTemplate(template.id);
        } else {
            chooseTemplate();
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedTemplateIds,
        onChange: onSelectTemplateChange,
        type: 'radio',
    };

    function rendertTemplatesTable() {
        const columns = [
            {
                dataIndex: 'id',
                render: (_, record) => {
                    return (
                        <div>
                            <b>{record.name}</b>
                            <br />
                            <img
                                src={`${apiBaseUrl}/api/media/getimage/templates/${record.fileName}`}
                                height={150}
                                onClick={() => {
                                    chooseTemplate(record.id);
                                }}
                                alt={record.fileName}
                            />
                        </div>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={templates}
                loading={loading}
                showHeader={false}
                pagination={{position: 'bottomRight', defaultPageSize: 3}}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectTemplateChange([record.id]);
                        },
                    };
                }}
                scroll={{ y: 'calc(100vh - 150px)' }}
            />
        );
    }
    const  selectedSys = getItemByIdOrNull(systems, selectedSysId);
    //const selectedSysId2 = 45;
    //console.log('render', typeof(selectedSysId2), typeof(selectedSysId), selectedSysId,  typeof(45));
    //console.log(selectedSysId, Number(selectedSysId));
    return (
        <Modal
            visible={true}
            showTitle={true}
            title={isEditMode ? tr('Изменить изделие') + ` ${ordno}` : tr('Добавить изделие')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => save()}
            cancelButtonProps={{ disabled: loading }}
            okButtonProps={{ disabled: loading }}
            width={isEditMode ? 900 : 1400}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={configForm}>
            <Row>
                {!isEditMode && (
                    <Col span={6}>
                        <Form colon={false} form={groupForm}>
                            <Form.Item name='id'>
                                <Select placeholder={tr('Выберите группу')} onChange={(value) => chooseSelectedGroup(value)}>
                                    {templateGroups.map((d) => {
                                        return (
                                            <Option key={`group${d.id}`} value={d.id}>
                                                {d.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Form>

                        {rendertTemplatesTable()}
                    </Col>
                )}

                <Col span={isEditMode ? 12 : 9}>
                        <><h2 className='order-product-title'>{tr('Основные')}</h2>
                        {/*selectedSys &&*/
                            renderCombobox(
                                systems,
                                tr('Система'),
                                tr('Выберите систему'),
                                selectedSysId,
                                chooseSelectedSys,
                                'selectedSysId'
                            )}
                        {renderCombobox(
                            zapolns,
                            tr('Заполнение'),
                            tr('Выберите заполнение'),
                            getItemIdOrNull(selectedZapoln),
                            (id) => {setSelectedZapoln(zapolns.find((e) => e.id === id));},
                            'selectedZapoln'
                        )}
                        {selectedSys &&
                            renderCombobox(
                                selectedSys.colorGroup.colors,
                                tr('Цвет'),
                                tr('Выберите цвет'),
                                getItemIdOrNull(selectedColor),
                                (id) => {setSelectedColor(selectedSys.colorGroup.colors.find((e) => e.id === id));},
                                'selectedColor'
                            )}
                        {getColorSideIsPallete(selectedColor, 'colorIn') &&
                            renderCombobox(
                                selectedColor.colorIn.colorCnt,
                                tr('Цвет изнутри'),
                                tr('Выберите цвет'),
                                getItemIdOrNull(selectedColorIn),
                                (id) => {setSelectedColorIn(selectedColor.colorIn.colorCnt.find((e) => e.id === id));},
                                'selectedColorIn'
                            )}
                        {getColorSideIsPallete(selectedColor, 'colorOut') &&
                            renderCombobox(
                                selectedColor.colorOut.colorCnt,
                                tr('Цвет снаружи'),
                                tr('Выберите цвет'),
                                getItemIdOrNull(selectedColorOut),
                                (id) => {setSelectedColorOut(selectedColor.colorOut.colorCnt.find((e) => e.id === id));},
                                'selectedColorOut'
                            )}
                        {getColorSideIsPallete(selectedColor, 'colorBase') &&
                            renderCombobox(
                                selectedColor.colorBase.colorCnt,
                                tr('Цвет основы'),
                                tr('Выберите цвет'),
                                getItemIdOrNull(selectedColorB),
                                (id) => {setSelectedColorB(selectedColor.colorBase.colorCnt.find((e) => e.id === id));},
                                'selectedColorB'
                            )}
                        {getColorSideIsPallete(selectedColor, 'colorF') &&
                            renderCombobox(
                                selectedColor.colorF.colorCnt,
                                tr('Цвет фальца'),
                                tr('Выберите цвет'),
                                getItemIdOrNull(selectedColorF),
                                (id) => {setSelectedColorF(selectedColor.colorF.colorCnt.find((e) => e.id === id));},
                                'selectedColorF'
                            )}
                        <Form.Item initialValue={width} label={`${tr('Ширина')}, ${tr('мм')}`} name='width'>
                            <Input
                                disabled={isEditMode}
                                onChange={(data) => {setWidth(data.target.value);}}
                            />
                        </Form.Item>
                        <Form.Item initialValue={height} label={`${tr('Высота')}, ${tr('мм')}`} name='height'>
                            <Input disabled={isEditMode} onChange={(data) => {setHeight(data.target.value);}}/>
                        </Form.Item>
                        <Form.Item initialValue={qty} label={tr('Количество')} name='qty'>
                            <Input
                                onChange={(data) => {
                                    setQty(data.target.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={constName} label={tr('Наименование')} name='constName'>
                            <Input onChange={(data) => {setConstName(data.target.value);}}/>
                        </Form.Item>

                        <Form.Item initialValue={comment} label={tr('Комментарий')} name='comment'>
                            <Input onChange={(data) => {setComment(data.target.value);}} />
                        </Form.Item>
                        {vsts.length > 0 &&
                            renderCombobox(
                                vsts,
                                tr('Вставка'),
                                tr('Выберите вставку'),
                                getItemIdOrNull(selectedVst),
                                (id) => {setSelectedVst(vsts.find((e) => e.id === id));},
                                'selectedVst'
                            )}
                        {monts.length > 0 &&
                            renderCombobox(
                                monts,
                                tr('Монтаж'),
                                tr('Выберите монтаж'),
                                getItemIdOrNull(selectedMont),
                                (id) => {setSelectedMont(monts.find((e) => e.id === id));},
                                'selectedMont'
                            )}
                        {furns.length > 0 &&
                            renderCombobox(
                                furns,
                                tr('Фурнитура'),
                                tr('Выберите фурнитуру'),
                                getItemIdOrNull(selectedFurn),
                                (id) => {setSelectedFurn(furns.find((e) => e.id === id));},
                                'selectedFurn'
                            )}
                        {diffs.length > 0 &&
                            renderCombobox(
                                diffs,
                                tr('Сложность'),
                                tr('Выберите сложность'),
                                getItemIdOrNull(selectedDiff),
                                (id) => {setSelectedDiff(diffs.find((e) => e.id === id));},
                                'selectedDiff'
                        )}
                        </>                        
                </Col>
                <Col span={isEditMode ? 12 : 9}>
                    {paramNames.length > 0 && paramValues && (
                            <>
                                <h2 className='order-product-title'>{tr('Параметры')}</h2>
                                {paramNames.map((paramName) => {
                                    return (
                                        <Form.Item
                                            key={`param${paramName.id}`}
                                            //name={`param${paramName.id}`}
                                            //initialValue={paramValues['param' + paramName.id]}
                                            label={paramName.name}
                                        >
                                            <Select
                                                placeholder={tr('Выберите значение')}
                                                onChange={(value) => {
                                                    let copy = { ...paramValues };
                                                    copy['param' + paramName.id] = value;
                                                    setParamValues(copy);
                                                }}
                                                value={paramValues['param' + paramName.id]}
                                            >
                                                {paramName.values.map((d) => {
                                                    return (
                                                        <Option key={`param${paramName.id}_${d.id}`} value={d.id}>
                                                            {d.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    );
                                })}
                            </>
                        )}
                </Col>

            </Row>
            </Form>
        </Modal>
    );
};

export default ProductModal;
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';

import { Table, Modal, Space, Button, notification, message } from 'antd';
import { ReloadOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import { serverFetch } from '../../server';
import { tr } from '../../utils/translate';

const PackageSelection = ({ orders, onClose }) => {
    const user = useSelector((s) => s.user);

    const [loading, setLoading] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState(true);
    const [packages, setPackages] = useState([]);
    const [currentPackage, setCurrentPackage] = useState();
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);

    useEffect(() => {
        if (refreshRequired) {
            setRefreshRequired(false);
            getData();
        }
    }, [refreshRequired]);

    const getData = () => {
        serverFetch(`packages/open`, { method: 'GET' }, user)
            .then((data) => {
                setPackages(data);
                setLoading(false);
            })
            .catch((e) => {
                message.error(
                    <>
                        {tr('Ошибка получения пакетов')}
                        <p>- {e.UserMessage}</p>
                    </>
                );

                setLoading(false);
            });
    };

    const renderToolbar = () => {
        return (
            <Space className='toolbal'>
                <Button
                    icon={<ReloadOutlined />}
                    onClick={() => {
                        setRefreshRequired(true);
                    }}
                >
                    {tr('Обновить')}
                </Button>
                <Button
                    disabled={!currentPackage}
                    type='primary'
                    icon={<AppstoreAddOutlined />}
                    onClick={() => {
                        serverFetch(`packages/addorders/${currentPackage.idDoc}`, { method: 'POST', bodyData: { orders: orders } }, user)
                            .then((result) => {
                                setLoading(false);
                                if (result.success == true) {
                                    notification['success']({
                                        message: tr('Заказы успешно добавлены в пакет') + ` #${currentPackage.idDoc}`,
                                    });
                                } else {
                                    notification['error']({
                                        message: result.error,
                                    });
                                }
                            })
                            .catch((e) => {
                                message.error(
                                    <>
                                        {tr('Ошибка добавления в пакет')}
                                        <p>- {e.UserMessage}</p>
                                    </>
                                );

                                setLoading(false);
                            });
                    }}
                >
                    {tr('В пакет')}
                </Button>

                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                        serverFetch(`packages/add`, { method: 'POST' }, user)
                            .then(() => {
                                setCurrentPackage();
                                setRefreshRequired(true);
                            })
                            .catch((e) => {
                                e.UserMessage && message.error(e.UserMessage);;
                            });
                    }}
                >
                    {tr('Создать пакет')}
                </Button>
            </Space>
        );
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedPackageIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let pack = packages.find((o) => o.idDoc == selectedRowKeys[0]);
            setCurrentPackage(pack);
        } else {
            setCurrentPackage();
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedPackageIds,
        onChange: onSelectChange,
        type: 'radio',
    };

    function renderTable() {
        const columns = [
            {
                title: '#',
                dataIndex: 'idDoc',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: (a, b) => a.idDoc - b.idDoc,
                width: 80,
            },
            {
                title: tr('Дата'),
                dataIndex: 'docdate',
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.docDate).unix() - moment(b.docdate).unix(),
                render: (_, record) => {
                    return <>{moment(record.docDate).format('DD.MM.YYYY')} </>;
                },
                width: 120,
            },
            {
                title: '№',
                dataIndex: 'docnum',
                width: 80,
            },
            {
                title: tr('Комментарий'),
                dataIndex: 'comment',
                width: 200,
            },

            {
                title: tr('Изделия'),
                dataIndex: 'countIzd',
                width: 120,
            },
            {
                title: tr('Макс.'),
                dataIndex: 'countIzdMax',
                width: 120,
            },
        ];

        return (
            <>
                <Table
                    rowKey='idDoc'
                    size='small'
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={packages}
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onSelectChange([record.idDoc]);
                            },
                        };
                    }}
                    scroll={{ y: 'calc(100vh - 514px)', x: 'calc(100vw - 40px)' }}
                    pagination={{
                        showSizeChanger: true,
                        current: 1,
                        defaultPageSize: 20,
                    }}
                />
            </>
        );
    }

    return (
        <Modal
            visible={true}
            title={tr('Пакеты')}
            showTitle={true}
            closeOnOutsideClick={false}
            showCloseButton={false}
            cancelText={tr('Закрыть')}
            onCancel={() => onClose()}
            okButtonProps={{ style: { display: 'none' } }}
            width={1000}
        >
            {renderToolbar()}
            {renderTable()}
        </Modal>
    );
};

export default PackageSelection;

import React from 'react';
import { useDispatch } from 'react-redux';
import { setCadSize } from '../../../store/actions';

import { Modal, Form, Input, message } from 'antd';
import { tr } from '../../../utils';

import { serverFetch } from '../../../server';

const CadCmdSetSizePopup = ({ vidOper, cadSize, onCancel, onOk }) => {
    const d = useDispatch();

    if (!cadSize) return null;

    const { cadSizeValStart, cadSizeVal } = cadSize;

    if (vidOper !== 'SET_SIZE') return null;

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={tr('Ввод размера')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => {
                onCancel();
            }}
            onOk={() => onOk()}
            width={300}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item initialValue={cadSizeVal} label={tr('Размер')} name='xValue'>
                    <Input
                        autoFocus
                        onChange={(data) => {
                            d(
                                setCadSize({
                                    vidOper: 'SET_SIZE',
                                    cadSizeVal: data.target.value,
                                    cadSizeValStart: cadSizeValStart,
                                })
                            );
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) onOk();
                        }}
                        placeholder={tr('Координата')}
                        onFocus={(event) => {
                            event.target.select();
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CadCmdSetSizePopup;

export async function CadCmdSetSizeDoOper(selectedObject, cadSize, data, user, refreshCad) {
    if (!cadSize) return;
    const { cadSizeValStart, cadSizeVal } = cadSize;

    let value = 0;
    if (selectedObject.pos === cadSizeValStart) {
        // Движение основной позиции элемента
        value = cadSizeVal - cadSizeValStart;
    } else {
        // Движение позиции элемента относительно габарита
        value = cadSizeValStart - cadSizeVal;
    }

    data = {
        ...data,
        kind: selectedObject.kindPos,
        size: value,
    };

    await serverFetch(`izd/opermove`, { method: 'POST', bodyData: data }, user)
        .then((data) => {
            refreshCad && refreshCad();
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
}

export const initialState = {
    order: null,
    user: null,
    izd: null,
    selectedObjectIds: [],
    cadUpdated: false,
    elemSize: null,
    cadSize: null,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'ORDER_LOADED':
            return {
                ...state,
                order: action.payload,
            };
        case 'USER_LOADED':
            return {
                ...state,
                user: action.payload,
            };
        case 'IZD_LOADED':
            return {
                ...state,
                izd: action.payload,
            };
        case 'IZD_SELECTED':
            return {
                ...state,
                selectedObjectIds: action.payload,
            };
        case 'SET_VID_OPER': {
            return {
                ...state,
                vidOper: action.payload && action.payload.vidOper,
            };
        }
        case 'SET_ELEM_SIZE':
            return {
                ...state,
                elemSize: action.payload,
            };
        case 'SET_CAD_SIZE':
            return {
                ...state,
                cadSize: action.payload,
            };
        case 'CAD_UPDATED':
            return {
                ...state,
                cadUpdated: action.payload,
            };

        default:
            return state;
    }
};

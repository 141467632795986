import { message } from 'antd';

import { serverFetch } from '../../../server';

export const CadCmdAlignStartOper = (vidOper, onDoOper) => {
    if (vidOper === 'ALIGN_GEOM' || vidOper === 'ALIGN_LIGHT') {
        onDoOper();
        return true;
    }

    return false;
};

export async function CadCmdAlignDoOper(selectedObjectIds, vidOper, data, user, refreshCad) {
    data = {
        ...data,
        ids: selectedObjectIds.join(),
        kind: vidOper === 'ALIGN_GEOM' ? 1 : 2, //ALIGN_GEOM = 1, ALIGN_LIGHT = 2
    };

    await serverFetch(`izd/operequal`, { method: 'POST', bodyData: data }, user)
        .then((data) => {
            refreshCad && refreshCad();
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
}

import React from 'react';
import { Line, Text } from 'react-konva';
import { getKanvaX, getKanvaY } from './cad-funcs';

// Константы
const sizeLineText = 30; // Размер ширины полосы отступа (для текста)
const sizeLineColor = "black";
const sizeLineWidth = 1;
const sizeLineStartName = "sizeStart";
const sizeLineWholeName = "sizeWhole";

// Отрисовать элемент
function getElemDraw(elem, id, points, closed, penColor, fillColor, onClick) {
    return (<Line
        key={`c${id}`}
        strokeWidth={1}
        points={points}
        closed={closed}
        stroke={penColor}
        fill={fillColor}
        onClick={onClick}
        objVid={elem.vid}
        objId={elem.id}
        elem={elem}
    />)
}

function getSizeArrow(x, y, direction, name) {
    let points = [];
    switch (direction) {
        case 'left': points = [x + 0.5 * sizeLineText, y - 0.1 * sizeLineText, x, y, x + 0.5 * sizeLineText, y + 0.1 * sizeLineText]
            break;
        case 'right': points = [x - 0.5 * sizeLineText, y - 0.1 * sizeLineText, x, y, x - 0.5 * sizeLineText, y + 0.1 * sizeLineText]
            break;
        case 'up': points = [x - 0.1 * sizeLineText, y + 0.5 * sizeLineText, x, y, x + 0.1 * sizeLineText, y + 0.5 * sizeLineText]
            break;
        case 'down': points = [x - 0.1 * sizeLineText, y - 0.5 * sizeLineText, x, y, x + 0.1 * sizeLineText, y - 0.5 * sizeLineText]
            break;
        default: points = [];
    }
    if (points.length !== 0) {
        return [<Line key={name} strokeWidth={sizeLineWidth} stroke={sizeLineColor} points={points} />]
    }
    return [];
}

function drawSizeX(elem, prefix, x1, x2, y, val, onClick) {
    let r = [];
    r.push(<Line // Выносная линия слева
        key={prefix + sizeLineStartName + '1'}
        strokeWidth={sizeLineWidth}
        points={[x1, y, x1, y + sizeLineText]}
        stroke={sizeLineColor}
    />);
    r.push(getSizeArrow(x1, y + 0.9 * sizeLineText, 'left', prefix + 'Arrow1')); // Стрелка слева
    r.push(<Line // Выносная линия справа
        key={prefix + sizeLineStartName + '2'}
        strokeWidth={sizeLineWidth}
        points={[x2, y, x2, y + sizeLineText]}
        stroke={sizeLineColor}        
    />);
    r.push(getSizeArrow(x2, y + 0.9 * sizeLineText, 'right', prefix + 'Arrow2')); // Стрелка справа
    r.push(  // Размерная линия
        <Line
            key={prefix + sizeLineWholeName}
            strokeWidth={sizeLineWidth}
            stroke={sizeLineColor}
            points={[x1, y + 0.9 * sizeLineText, x2, y + 0.9 * sizeLineText]}
        />
    );
    r.push(<Text  // Текст размера
        text={val}
        fill={sizeLineColor}
        key="prefix + SizeText1"
        x={(x1 + x2) / 2}
        y={y + sizeLineText * 0.1}
        fontSize={sizeLineText * 0.7}
        elem={elem}
        sizeVal={val}
        onClick={onClick}
    />);
    return r;
}

function drawSizeY(elem, prefix, y1, y2, x, val, onClick) {
    let r = [];
    r.push(<Line // Выносная линия снизу
        key={prefix + sizeLineStartName + '1'}
        strokeWidth={sizeLineWidth}
        points={[x, y1, x + sizeLineText, y1]}
        stroke={sizeLineColor}
    />);
    r.push(getSizeArrow(x + 0.9 * sizeLineText, y1, 'down', prefix + 'Arrow1')); // Стрелка снизу
    r.push(<Line // Выносная линия сверху
        key={prefix + sizeLineStartName + '2'}
        strokeWidth={sizeLineWidth}
        points={[x, y2, x + sizeLineText, y2]}
        stroke={sizeLineColor}
    />);
    r.push(getSizeArrow(x + 0.9 * sizeLineText, y2, 'up', prefix + 'Arrow2')); // Стрелка сверху
    r.push(<Line // Размерная линия
        key={prefix + sizeLineWholeName}
        strokeWidth={sizeLineWidth}
        stroke={sizeLineColor}
        points={[x + 0.9 * sizeLineText, y1, x + 0.9 * sizeLineText, y2]}
    />);
    r.push(<Text // Текст размера
        text={val}
        fill={sizeLineColor}
        key="prefix + SizeText1"
        y={(y1 + y2) / 2}
        x={x + sizeLineText * 0.1}
        fontSize={sizeLineText * 0.7}
        elem={elem}
        sizeVal={val}
        rotation={270}
        onClick={onClick}
    />);
    return r;
}

// Отрисовать размерные линии элемента
function getElemSizeDraw(elem, izd, onClick, { canvasDx, canvasDy, canvasZoom }) {
    let r = [];
    if (elem.kindPos === 1) { // Позиция по X
        if (Number(elem.pos) !== 0) {
            r.push(drawSizeX(elem, `es${elem.id}_1_`, canvasDx, getKanvaX(elem.pos, { canvasDx, canvasZoom }), canvasDy, elem.pos, onClick))
        }
        if (Number(elem.pos) !== Number(izd.w)) {
            r.push(drawSizeX(elem, `es${elem.id}_2_`, getKanvaX(elem.pos, { canvasDx, canvasZoom }), getKanvaX(izd.w, { canvasDx, canvasZoom }), canvasDy, izd.w - elem.pos, onClick))
        }
    }
    if (elem.kindPos === 2) { // Позиция по Y
        if (Number(elem.pos) !== 0) {
            r.push(drawSizeY(elem, `es${elem.id}_1_`, canvasDy, getKanvaY(elem.pos, { canvasDy, canvasZoom }), getKanvaX(izd.w, { canvasDx, canvasZoom }), elem.pos, onClick))
        }
        if (Number(elem.pos) !== Number(izd.h)) {
            r.push(drawSizeY(elem, `es${elem.id}_1_`, getKanvaY(elem.pos, { canvasDy, canvasZoom }), getKanvaY(izd.h, { canvasDy, canvasZoom }), getKanvaX(izd.w, { canvasDx, canvasZoom }), izd.h - elem.pos, onClick))
        }
    }
    return r;
}

export { getElemDraw, getElemSizeDraw };
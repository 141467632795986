import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, message } from 'antd';

import { tr } from '../../utils/translate';
import { serverFetch } from '../../server';

const PackageProperties = ({ ...props }) => {
    const user = useSelector((s) => s.user);

    const { onChange } = props;

    const [pack] = useState(props.pack);

    const [comment, setComment] = useState(pack.comment);

    const onSave = () => {
        let data = {
            id: pack.idDoc,
            comment: comment,
        };
        serverFetch(`packages`, { method: 'PUT', bodyData: data }, user)
            .then(() => {
                onChange(true);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);;
            });
    };

    return (
        <>
            <Modal
                visible={true}
                showTitle={true}
                title={tr('Пакет')}
                closeOnOutsideClick={false}
                showCloseButton={false}
                okText={tr('ОК')}
                cancelText={tr('Отмена')}
                onCancel={() => onChange(false)}
                onOk={() => onSave()}
            >
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item initialValue={pack.idDoc} label='#' name='idDoc'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={pack.docNum} label={tr('Номер')} name='docNum'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={pack.docDate} label={tr('Дата')} name='docDate'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={comment} label={tr('Комментарий')} name='comment'>
                        <Input
                            onChange={(data) => {
                                setComment(data.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={pack.countAcc} label={tr('Заказы')} name='countAcc'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={pack.sumAcc} label={tr('Сумма')} name='sumAcc'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={pack.countIzd} label={tr('Изделия')} name='countIzd'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={pack.sqrtIzd} label={tr('Площадь')} name='sqrtIzd'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item initialValue={pack.countIzdMax} label={tr('Макс.')} name='countIzdMax'>
                        <Input disabled={true} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PackageProperties;

import React from 'react';
import { Modal, Select, Form, message } from 'antd';

import { serverFetch } from '../../../server';
import { tr } from '../../../utils';

export const CadCmdMoskPopup = ({ vidOper, mosks, selectedMoskValue, onCancel, onOk, onChange }) => {
    const { Option } = Select;

    if (vidOper !== 'MOSK') return null;

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={tr('Выбор москитной сетки')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
            width={600}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item initialValue={selectedMoskValue} label={tr('Москитная сетка')} name='selectedMoskValue'>
                    <Select placeholder={tr('Выберите москитную сетку')} onChange={(value) => onChange(mosks.find((e) => e.id === value))}>
                        {mosks.map((d) => {
                            return (
                                <Option key={`selectedMoskValue${d.id}`} value={d.id}>
                                    {d.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const CadCmdMoskStartOper = (selectedObject, izd, onChange) => {
    if (selectedObject === null) return true;
    // Получить контур выделенного элемента
    const selectedCons = izd.cadElems.find((item) => item.id === selectedObject.cons);
    if (selectedCons === null) return true;

    onChange(selectedCons.idMosk);
};

export async function CadCmdMoskDoOper(selectedConsIds, selectedMoskValue, data, user, refreshCad) {
    data = {
        ...data,
        ids: selectedConsIds.join(),
        IdSpr: selectedMoskValue.id,
    };

    await serverFetch(`izd/operchangemosk`, { method: 'POST', bodyData: data }, user)
        .then((data) => {
                refreshCad && refreshCad();
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
}

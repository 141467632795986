import React from 'react';
import { Stage, Layer } from 'react-konva';
import { connect } from 'react-redux';
import { izdSelected } from '../../store/actions';
import Izd from './izd';

class CadCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }
    render() {
        const height = this.state.height - 100;
        const width = this.state.width - 100;
        return (
            <div style={{ marginLeft: 60 }}>
                <Stage width={width} height={height}>
                    <Layer>
                        <Izd
                            canvasHeight={height}
                            canvasWidth={width}
                            izd={this.props.izd}
                            selectedObjectIds={this.props.selectedObjectIds}
                            izdSelected={this.props.izdSelected}
                            sizeClick={this.props.sizeClick}
                        />
                    </Layer>
                </Stage>
            </div>
        );
    }
}

const mapStateToProps = ({ izd, selectedObjectIds }) => {
    return { izd, selectedObjectIds };
};

export default connect(mapStateToProps, { izdSelected })(CadCanvas);

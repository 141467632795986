import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router';

import { Spin, message } from 'antd';

import CadCanvas from './cad-canvas';
import CadTools from './cad-tools';
import { izdLoaded, setCadSize, setCadUpdated } from '../../store/actions';
import { CadImage } from './cad-image';
import { serverFetch } from '../../server';

import { tr } from '../../utils';

import './cad.css';

const Cad = () => {
    const { id, orderGuid } = useParams();

    const d = useDispatch();
    const user = useSelector((s) => s.user);
    const cadUpdated = useSelector((s) => s.cadUpdated);

    const [picture, setPicture] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingImg, setLoadingImg] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (cadUpdated) {
            d(setCadUpdated(false));

            setLoading(true);
            setLoadingImg(true);

            loadData();
        }
    }, [cadUpdated]);

    const loadData = () => {
        serverFetch(`orders/constructionscheme/${orderGuid}/${id}`, { method: 'GET' }, user)
            .then((data) => {
                d(izdLoaded(data));
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });

        serverFetch(`orders/getizdimg/${orderGuid}/${id}/300`, { method: 'GET', blob: true }, user)
            .then((data) => {
                setLoadingImg(false);
                data && setPicture(URL.createObjectURL(data));
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);;
            });
    };

    const sizeClick = (e) => {
        d(
            setCadSize({
                cadSizeVal: e.target.attrs.sizeVal,
                cadSizeValStart: e.target.attrs.sizeVal,
            })
        );
    };

    return (
        <>
            {loading || loadingImg ? (
                <div className='processing'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spin size='large' tip={tr('Пожалуйста, подождите...')} />
                    </div>
                </div>
            ) : (
                <>
                    <CadTools />
                    <CadCanvas sizeClick={sizeClick} />
                    <CadImage picture={picture} />
                </>
            )}
        </>
    );
};

export default Cad;

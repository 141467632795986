import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { Layout, Avatar, notification, message } from 'antd';

import { Menu } from 'antd';
import { ShoppingCartOutlined, InboxOutlined, UserOutlined, LogoutOutlined, UserSwitchOutlined } from '@ant-design/icons';

import './NavMenu.css';
import Login from './login.js';

import { tr } from '../utils';
import { permission, hasPermission } from '../extensions/helper';
import { userLoaded } from '../store/actions';

import { serverFetch } from '../server';

const NavMenu = () => {
    const { Header } = Layout;

    const d = useDispatch();

    const [current, setCurrent] = useState();

    const user = useSelector((s) => s.user);

    useEffect(() => {
        if (user) {
            const pathname = window.location.pathname;
            setCurrent(pathname && pathname !== '/' ? pathname.slice(1) : 'orders');
        }
    }, [user]);

    const handleClick = (e) => {
        switch (e.key) {
            case 'loadSettings': {
                serverFetch(`repository/updateSettings`, { method: 'POST' }, user)
                    .then(() =>
                        notification['success']({
                            message: tr('Новые настройки успешно загружены'),
                        })
                    )
                    .catch((e) => {
                        e.UserMessage && message.error(e.UserMessage);
                    });
                break;
            }
            case 'logout': {
                d(userLoaded(null));
                break;
            }
            default:
                break;
        }
        console.log('e.key', e.key);
        if (e.key === 'orders' || e.key === 'packages' || e.key === 'usermanagement') {
            setCurrent(e.key);
        }
    };

    return (
        <Header className='header'>
            <div className='logo'>
                {user && user.companyLogo && (
                    <Avatar src={`data:image/jpeg;base64,${user.companyLogo}`} style={{ verticalAlign: 'middle' }} size='large'></Avatar>
                )}
            </div>

            <div className='logoTitle'>
                <a href='/'>
                    {(user && user.companyName) || tr('ИТ Окна Онлайн')} {process.env.REACT_APP_VERSION}
                </a>
            </div>

            {user ? (
                <Menu
                    key='menu'
                    style={{ float: 'right' }}
                    onClick={handleClick}
                    selectedKeys={[current]}
                    mode='horizontal'
                    theme='light'
                    disabledOverflow={true}
                >
                    <Menu.Item key='orders' icon={<ShoppingCartOutlined />}>
                        <Link to='/orders'>{tr('Заказы')}</Link>
                    </Menu.Item>

                    <Menu.Item
                        disabled={!hasPermission(user.permissions, permission.AddOrderToPackage)}
                        key='packages'
                        icon={<InboxOutlined />}
                    >
                        <Link to='/packages'>{tr('Пакеты')}</Link>
                    </Menu.Item>

                    <Menu.Item
                        disabled={!hasPermission(user.permissions, permission.CanManageUsers)}
                        key='usermanagement'
                        icon={<UserSwitchOutlined />}
                    >
                        <Link to='/usermanagement'>{tr('Пользователи')}</Link>
                    </Menu.Item>

                    <Menu.SubMenu key='user' icon={<UserOutlined />} title={user.userName}>
                        <Menu.Item key='logout' icon={<LogoutOutlined />}>
                            {tr('Выход')}
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            ) : (
                <Menu key='login' style={{ float: 'right' }} theme='light'>
                    <Login />
                </Menu>
            )}
        </Header>
    );
};

{
    /*  <Navbar className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3' light>
    <Container>
        <NavbarBrand tag={Link} to='/'>
          
        </NavbarBrand>
        <NavbarToggler onClick={() => setCollapsed(!collapsed)} className='mr-2' />
        <Collapse className='d-sm-inline-flex flex-sm-row-reverse' isOpen={!collapsed} navbar>
            
            {navMenu}
        </Collapse>
    </Container>
</Navbar> */
}

export default NavMenu;

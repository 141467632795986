import { Modal, message } from 'antd';

import { tr } from '../../../utils';
import { serverFetch } from '../../../server';

export const CadCmdDelStartOper = (onOk) => {
    Modal.confirm({
        title: tr('Удалить выбранный элемент?'),
        okText: tr('ОК'),
        cancelText: tr('Отмена'),
        onOk: () => {
            onOk();
        },
    });
};

export async function CadCmdDelDoOper(selectedObject, data, user, refreshCad) {
    if (selectedObject.vid === 2) {
        await serverFetch(`izd/operdelete/${data.orderGuid}/${data.idStr}/${data.id}`, { method: 'DELETE' }, user)
            .then((data) => {
                refreshCad && refreshCad();
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);;
            });
    } else {
        await serverFetch(`izd/operdeletecons/${data.orderGuid}/${data.idStr}/${selectedObject.cons}`, { method: 'DELETE' }, user)
            .then((data) => {
                refreshCad && refreshCad();
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);;
            });
    }
}

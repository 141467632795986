import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { Modal, Tabs, Form, Input, Typography, Alert, message } from 'antd';

import '../shared.css';
import { tr } from '../../utils/translate';
import { serverFetch } from '../../server';

const OrderHead = ({ orderGuid, onCancel, onSave }) => {
    const { TabPane } = Tabs;
    const { Text } = Typography;

    const user = useSelector((s) => s.user);

    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        if (!orderGuid) {
            setLoading(false);
            setOrder({
                docdate: new Date(),
                orderGuid: '',
            });
        } else {
            serverFetch(`orders/orderHead/${orderGuid}`, { method: 'GET' }, user)
                .then((data) => {
                    setOrder(data);
                    setLoading(false);
                })
                .catch((e) => {
                    e.UserMessage && message.error(e.UserMessage);
                });
        }
    };

    const save = () => {
        setLoading(true);
        serverFetch(`orders/saveorder`, { method: 'POST', bodyData: order }, user)
            .then((data) => {
                setLoading(false);
                onSave();
            })
            .catch((e) => {
                setLoading(false);
                message.error(
                    <>
                        {tr('Ошибка сохранения заказа')}
                        <p> - {e.UserMessage}</p>
                    </>
                );
            });
    };

    if (!order) return <div>{tr('Заказ не найден')}</div>;

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={tr('Заказ')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => save()}
            okButtonProps={{ disabled: order.isReadOnly || loading }}
            bodyStyle={{ paddingTop: 10 }}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                {order.readOnlyDescription && <Alert message={order.readOnlyDescription} type='warning' />}

                <Tabs defaultActiveKey='1'>
                    <TabPane tab={tr('Заказ')} key='1'>
                        <Form.Item>
                            <Text style={{ fontWeight: 'bold' }}>
                                {tr('Заказ')} {order.depno} / {order.docnum} {tr('от')} {moment(order.docdate).format('DD.MM.YYYY')}
                            </Text>
                        </Form.Item>
                        <Form.Item initialValue={order.dAddress} label={tr('Адрес доставки')} name='dAddress'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, dAddress: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.comment} label={tr('Комментарий')} name='comment'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, comment: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.syma} label={tr('Сумма')} name='syma'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item initialValue={order.plat} label={tr('Оплаченои')} name='plat'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item initialValue={order.dolg} label={tr('Долг')} name='dolg'>
                            <Input disabled={true} />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab={tr('Клиент')} key='2'>
                        <Form.Item initialValue={order.cName} label={tr('Наименование')} name='cName'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, cName: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.cAddress} label={tr('Адрес')} name='cAddress'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, cAddress: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.cPhone} label={tr('Телефон')} name='cPhone'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, cPhone: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.cPassport} label={tr('Паспорт')} name='cPassport'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, cPassport: data.target.value });
                                }}
                            />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab={tr('Скидки')} key='3'>
                        <Form.Item initialValue={order.dAcc} label={tr('На заказ, %')} name='dAcc'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, dAcc: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.dIzd} label={tr('На изделия, %')} name='dIzd'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, dIzd: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.dAks} label={tr('На аксессуары, %')} name='dAks'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, dAks: data.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={order.dJob} label={tr('На работы, %')} name='dJob'>
                            <Input
                                disabled={order.isReadOnly}
                                onChange={(data) => {
                                    setOrder({ ...order, dJob: data.target.value });
                                }}
                            />
                        </Form.Item>
                    </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
};

export default OrderHead;

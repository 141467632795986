import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import { Typography, Table, Space, Button, DatePicker, Drawer, Row, Col, Form, Switch, Tooltip, message } from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    FolderOpenOutlined,
    ReloadOutlined,
    DownloadOutlined,
    RollbackOutlined,
    DeleteOutlined,
    SettingOutlined,
    SaveFilled,
} from '@ant-design/icons';

import * as FileSaver from 'file-saver';
import PackageProperties from './package-properties';
import PackageDetails from './package-details';
import { tr } from '../../utils/translate';
import { serverFetch } from '../../server';

const settingContext = 'Packages';
const settingType = 'TableColumns';

const Packages = () => {
    const { Text } = Typography;

    const [settingsForm] = Form.useForm();

    const user = useSelector((s) => s.user);

    const [packages, setPackages] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);
    const [currentPackage, setCurrentPackage] = useState();
    const [editMode, setEditMode] = useState(false);
    const [detailsMode, setDetailsMode] = useState(false);

    const [filter, setFilter] = useState({});
    const [loading, setLoading] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState(true);

    const [openSettings, setOpenSettings] = useState(false);
    const [columnSettings, setColumnSettings] = useState();

    useEffect(() => {
        let storedFilter = localStorage.getItem('packagesFilter');

        if (storedFilter) {
            storedFilter = JSON.parse(storedFilter);
            setFilter({
                ...storedFilter,
                dateFrom: storedFilter.dateFrom && moment(storedFilter.dateFrom),
                dateTo: storedFilter.dateTo && moment(storedFilter.dateTo),
            });
        } else {
            setFilter({
                dateFrom: moment().add(-1, 'days'),
                dateTo: moment(),
            });
        }

        getStatuses();
    }, []);

    useEffect(() => {
        if (filter.dateFrom && filter.dateTo && refreshRequired) {
            setRefreshRequired(false);
            getData();
        }

        localStorage.setItem('packagesFilter', JSON.stringify(filter));
    }, [filter, refreshRequired]);

    const getStatuses = () => {
        serverFetch(`orders/statuses`, { method: 'GET' }, user)
            .then((statuses) => setStatuses(statuses))
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const getData = () => {
        setLoading(true);

        let queryParams = {
            dateFrom: filter.dateFrom && filter.dateFrom.format(),
            dateTo: filter.dateTo && filter.dateTo.format(),
        };

        serverFetch('packages', { method: 'GET', queryParams: queryParams }, user)
            .then((data) => {
                setPackages(data);
                setLoading(false);
                setEditMode(false);
            })
            .catch((e) => {
                message.error(
                    <>
                        {tr('Ошибка получения пакетов')}
                        <p>- {e.UserMessage}</p>
                    </>
                );

                message.error(tr(''));
                setLoading(false);
            });

        let settingsQueryParams = {
            settingType: settingType,
            context: settingContext,
        };

        serverFetch('usersettings', { method: 'GET', queryParams: settingsQueryParams }, user)
            .then((data) => {
                let settings = [];
                if (data) {
                    settings = JSON.parse(data.settingValue);
                } else {
                    let columns = getColumns();

                    columns.map((c) => {
                        settings.push({ visible: true, column: c.dataIndex, title: c.title });
                    });
                }

                setColumnSettings(settings);
            })
            .catch((e) => {
                message.error(tr('Ошибка получения настроек'));
                setLoading(false);
            });
    };

    const onChange = (refresh) => {
        setRefreshRequired(refresh);
        setEditMode(false);
    };

    const renderToolbar = () => {
        return (
            <Row>
                <Col span={23} align='left'>
                    <Space className='toolbal'>
                        <Text>{tr('Пакеты за период')}</Text>
                        <DatePicker
                            format='DD.MM.YYYY'
                            allowClear={false}
                            value={filter.dateFrom}
                            onChange={(dateX, date) => {
                                var dateFrom =
                                    date &&
                                    moment(date, 'DD.MM.YYYY').utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

                                setFilter({ ...filter, dateFrom: dateFrom });

                                setRefreshRequired(true);
                            }}
                        />
                        <Text>-</Text>
                        <DatePicker
                            format='DD.MM.YYYY'
                            allowClear={false}
                            value={filter.dateTo}
                            onChange={(dateX, date) => {
                                var dateTo =
                                    date &&
                                    moment(date, 'DD.MM.YYYY').utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

                                setFilter({ ...filter, dateTo: dateTo });

                                setRefreshRequired(true);
                            }}
                        />
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                setRefreshRequired(true);
                            }}
                        >
                            {tr('Обновить')}
                        </Button>
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => {
                                serverFetch(`packages/add`, { method: 'POST' }, user)
                                    .then(() => {
                                        setCurrentPackage();
                                        setRefreshRequired(true);
                                    })
                                    .catch((e) => {
                                        e.UserMessage && message.error(e.UserMessage);
                                    });
                            }}
                        >
                            {tr('Добавить')}
                        </Button>
                        <Button
                            disabled={!currentPackage}
                            icon={<EditOutlined />}
                            onClick={() => {
                                setEditMode(true);
                            }}
                        >
                            {tr('Изменить')}
                        </Button>
                        <Button
                            disabled={!currentPackage}
                            type='primary'
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                serverFetch(`packages/${currentPackage.idDoc}`, { method: 'DELETE' }, user)
                                    .then(() => {
                                        setCurrentPackage();
                                        setRefreshRequired(true);
                                    })
                                    .catch((e) => {
                                        e.UserMessage && message.error(e.UserMessage);
                                    });
                            }}
                        >
                            {tr('Удалить')}
                        </Button>
                        <Button disabled={!currentPackage} icon={<FolderOpenOutlined />} onClick={() => setDetailsMode(true)}>
                            {tr('Детали')}
                        </Button>

                        <Button
                            disabled={!currentPackage}
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                let name = 'it-okna_pack_' + currentPackage.idDoc;
                                serverFetch(`packages/download/${currentPackage.idDoc}`, { method: 'GET', blob: true }, user)
                                    .then((data) => {
                                        const blob = new Blob([data], { type: 'application/xml' });

                                        if (blob.size > 3) {
                                            FileSaver.saveAs(blob, name + '.itp');
                                        }
                                    })
                                    .catch((e) => {
                                        e.UserMessage && message.error(e.UserMessage);
                                    });
                            }}
                        >
                            {tr('Скачать')}
                        </Button>

                        <Button
                            disabled={!currentPackage}
                            icon={<RollbackOutlined />}
                            onClick={() => {
                                serverFetch(`packages/cancel`, { method: 'PUT', bodyData: { id: currentPackage.idDoc } }, user)
                                    .then(() => {
                                        setRefreshRequired(true);
                                    })
                                    .catch((e) => {
                                        e.UserMessage && message.error(e.UserMessage);
                                    });
                            }}
                        >
                            {tr('Отмена')}
                        </Button>
                    </Space>
                </Col>
                <Col span={1} align='right'>
                    <Button shape='circle' icon={<SettingOutlined />} onClick={() => setOpenSettings(true)} />
                </Col>
            </Row>
        );
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedPackageIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let currentPackage = packages.find((o) => o.idDoc == selectedRowKeys[0]);
            setCurrentPackage(currentPackage);
        } else {
            setCurrentPackage();
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedPackageIds,
        onChange: onSelectChange,
    };

    const getColumns = () => {
        return [
            {
                title: tr('Вид'),
                dataIndex: 'vid',
                width: 80,
            },
            {
                title: tr('Канал'),
                dataIndex: 'vidSend',
                width: 80,
            },
            {
                title: 'N',
                dataIndex: 'idDoc',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: (a, b) => a.idDoc - b.idDoc,
                width: 60,
            },
            {
                title: tr('Дата'),
                dataIndex: 'docdate',
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.docDate).unix() - moment(b.docdate).unix(),
                render: (_, record) => {
                    return <>{moment(record.docDate).format('DD.MM.YYYY')} </>;
                },
                width: 120,
            },
            {
                title: tr('Счетов'),
                dataIndex: 'countAcc',
                width: 80,
            },
            {
                title: tr('Изделия'),
                dataIndex: 'countIzd',
                width: 80,
            },
            {
                title: tr('Сумма'),
                dataIndex: 'sumAcc',
                width: 120,
            },
            {
                title: tr('Площадь'),
                dataIndex: 'sqrtIzd',
                width: 120,
            },
            {
                title: tr('Макс.'),
                dataIndex: 'countIzdMax',
                width: 100,
            },
            {
                title: tr('Комментарий'),
                dataIndex: 'comment',
                width: 200,
            },
            {
                title: tr('Создан'),
                dataIndex: 'date',
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
                render: (_, record) => {
                    return <>{moment(record.dateCreate).format('DD.MM.YYYY')} </>;
                },
                width: 120,
            },
            {
                title: tr('Пользователь'),
                dataIndex: 'userCreate',
                width: 200,
            },
        ];
    };

    function renderTable() {
        let columns = [];
        getColumns().map((c) => {
            let setting = columnSettings.find((s) => s.column == c.dataIndex);
            if (setting && setting.visible) columns.push(c);
        });

        return (
            <>
                <Table
                    rowKey='idDoc'
                    size='small'
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={packages}
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onSelectChange([record.idDoc]);
                            },
                        };
                    }}
                    scroll={{ y: 'calc(100vh - 251px)', x: 'calc(100vw - 40px)' }}
                    pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 20,
                    }}
                />
            </>
        );
    }

    const divPackPropertiesHead = editMode ? <PackageProperties pack={currentPackage || {}} onChange={(e) => onChange(e)} /> : null;

    const divDetails = detailsMode ? <PackageDetails packageId={currentPackage.idDoc} onClose={() => setDetailsMode(false)} /> : null;

    const onClose = () => {
        setOpenSettings(false);
    };

    const renderTableSettings = () => {
        return (
            <Drawer
                title='Настройка колонок'
                width={300}
                onClose={onClose}
                visible={openSettings}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Tooltip title='Сохранить настройки'>
                            <Button
                                icon={<SaveFilled />}
                                size='large'
                                type='primary'
                                onClick={() => {
                                    Object.entries(settingsForm.getFieldsValue()).map((e) => {
                                        let setting = columnSettings.find((s) => s.column == e[0]);
                                        if (setting) {
                                            setting.visible = e[1];
                                        }
                                    });

                                    let data = {
                                        settingType: settingType,
                                        context: settingContext,
                                        settingValue: JSON.stringify(columnSettings),
                                    };

                                    serverFetch(`usersettings`, { method: 'POST', bodyData: data }, user)
                                        .then((data) => {
                                            setLoading(false);
                                        })
                                        .catch((e) => {
                                            e.UserMessage && message.error(e.UserMessage);
                                            setLoading(false);
                                        });
                                    onClose();
                                }}
                            ></Button>
                        </Tooltip>
                    </Space>
                }
            >
                <Form layout='horizontal' labelCol={{ span: 8 }} wrapperCol={{ span: 4 }} form={settingsForm}>
                    {columnSettings.map((s) => {
                        return (
                            <Form.Item initialValue={s.visible} key={s.column} label={s.title} name={s.column} valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                        );
                    })}
                </Form>
            </Drawer>
        );
    };

    return (
        <>
            {renderToolbar()}

            {columnSettings && renderTable()}
            {columnSettings && renderTableSettings()}

            {divPackPropertiesHead}
            {divDetails}
        </>
    );
};

export default Packages;

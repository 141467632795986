import React from 'react';
import { stringToPoint, delphiToRgb } from './cad-funcs';
import { getElemDraw, getElemSizeDraw } from './cad-elem';

class Izd extends React.Component {
    constructor(props) {
        super(props);
        // Константы
        this.sizeLineText = 30; // Размер ширины полосы отступа (для текста)
        this.elemSelectedColor = 'blue';
        // Смещение
        const maxX = Number(props.izd.w);
        const maxY = Number(props.izd.h);
        // Масштаб - как меньший из масштабов по X и Y
        const canvasZoom =
            maxX === 0 || maxY === 0
                ? 1
                : Math.min((props.canvasHeight - 2 * this.sizeLineText) / maxY, (props.canvasWidth - 2 * this.sizeLineText) / maxX);
        const canvasDx = props.canvasWidth / 2 - this.sizeLineText - (maxX / 2) * canvasZoom;
        const canvasDy = props.canvasHeight / 2 + (maxY / 2) * canvasZoom;
        this.state = {
            canvasHeight: props.canvasHeight,
            canvasWidth: props.canvasWidth,
            canvasZoom,
            canvasDx,
            canvasDy,
        };
    }

    objectClick = (e) => {
        let newObjectIds = [];
        const objId = e.target.attrs.objId;
        if (e.evt.shiftKey) {
            newObjectIds = [...this.props.selectedObjectIds];
            if (newObjectIds.indexOf(objId) === -1) {
                newObjectIds.push(objId);
            } else {
                newObjectIds.pop(objId);
            }
        } else {
            newObjectIds = [objId];
        }
        this.props.izdSelected(newObjectIds);
    };

    render() {
        function isSelected(ids, id) {
            return ids.indexOf(id) !== -1;
        }
        // Отрисовка элементов
        let id = 0;
        const drawElems = [];
        this.props.izd.cadElems.forEach((elem) => {
            if (elem.cadContours !== undefined) {
                elem.cadContours.forEach((contour) => {
                    id = id + 1;
                    drawElems.push(
                        getElemDraw(
                            elem,
                            id,
                            stringToPoint(contour.coord, this.state.canvasDx, this.state.canvasDy, this.state.canvasZoom),
                            contour.closed,
                            delphiToRgb(contour.pen),
                            isSelected(this.props.selectedObjectIds, elem.id) ? this.elemSelectedColor : delphiToRgb(contour.brush),
                            this.objectClick
                        )
                    );
                });
            }
        });
        // Отрисовка размерных линий
        if (this.props.selectedObjectIds.length === 1) {
            const elem = this.props.izd.cadElems.find((elem) => elem.id === this.props.selectedObjectIds[0]);
            if (elem !== undefined && elem.vid === 2) {
                drawElems.push(getElemSizeDraw(elem, this.props.izd, this.props.sizeClick, this.state));
            }
        }
        return drawElems;
    }
}

export default Izd;

import React, { useState, useEffect } from 'react';
import { Spin, Select, Modal, Form, Input, message } from 'antd';

import { tr } from '../../utils/translate';
import { serverFetch } from '../../server';

const kindsAks = [
    { id: 0, name: tr('Аксессуар') },
    { id: 1, name: tr('Комплект') },
    { id: 2, name: tr('Работа') },
];

const OrderAks = ({ selectedAks, izds, order, user, onCancel, onSave }) => {
    const { Option } = Select;
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        selectedColorId: selectedAks ? selectedAks.idColor : undefined,
        selectedColor: undefined,
        selectedColorInId: selectedAks ? selectedAks.idColorIn : undefined,
        selectedColorOutId: selectedAks ? selectedAks.idColorOut : undefined,
        selectedColorBId: selectedAks ? selectedAks.idColorB : undefined,
        selectedColorFId: selectedAks ? selectedAks.idColorF : undefined,
        w: selectedAks ? selectedAks.w : undefined,
        h: selectedAks ? selectedAks.h : undefined,
        qty: selectedAks ? selectedAks.qty : 1,
        idIzd: selectedAks ? selectedAks.idStr : undefined,
    });

    const [selectedItem, setSelectedItem] = useState(selectedAks ? selectedAks.idMat : undefined);
    const [params, setParams] = useState();
    const [paramValues, setPramValues] = useState([]);
    const [items, setItems] = useState([]);
    const [tipR, setTipR] = useState([]);
    const [colors, setColors] = useState([]);
    const [vid, setVid] = useState(selectedAks ? selectedAks.matVid : 0);

    useEffect(() => {
        if (selectedAks) {
            let xParams = {};

            selectedAks.paramValues.forEach((item) => {
                xParams['param' + item.idName] = item.idVal;
            });

            setPramValues({ ...xParams });
        }

        onChooseVid(vid);
        selectedItem && onChooseItem(selectedItem);
        state.selectedColorId && onChooseColor(state.selectedColorId);

        setLoading(false);

        return () => {
            setState({});
            setVid(0);
        };
    }, []);

    useEffect(() => {
        const selectedItemObj = items.find((item) => item.id === selectedItem);
        setTipR(selectedItemObj && selectedItemObj.tipR);
    }, [items, selectedItem]);

    const save = () => {
        if (!selectedItem) {
            message.warning(tr('Не выбран материал'));
            return;
        }

        const idMat = vid === 1 ? 0 : selectedItem;
        const idKompl = vid === 1 ? selectedItem : 0;

        let sParam = [];

        params &&
            params.forEach((pn) =>
                sParam.push({
                    IdName: pn.id,
                    IdVal: paramValues['param' + pn.id],
                })
            );

        let data = {
            orderGuid: order.orderGuid,
            idElem: selectedAks && selectedAks.elem,
            idDetal: selectedAks && selectedAks.detal,
            idKompl: idKompl,
            idMat: idMat,
            idColor: state.selectedColorId,
            idColorIn: getColorSideIsPallete(state.selectedColor, 'colorIn') ? state.selectedColorInId : 0,
            idColorOut: getColorSideIsPallete(state.selectedColor, 'colorOut') ? state.selectedColorOutId : 0,
            idColorB: getColorSideIsPallete(state.selectedColor, 'colorBase') ? state.selectedColorBId : 0,
            idColorF: getColorSideIsPallete(state.selectedColor, 'colorF') ? state.selectedColorFId : 0,
            idStr: vid === 2 ? 0 : state.idIzd,
            w: tipR > 0 ? state.w : 0,
            h: tipR > 1 ? state.h : 0,
            qty: state.qty,
            sParam: JSON.stringify(sParam),
        };

        setLoading(true);

        serverFetch(`orders/aksadd`, { method: 'POST', bodyData: data }, user)
            .then((data) => {
                setLoading(false);
                onSave();
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onChooseVid = async (vid) => {
        setLoading(true);

        serverFetch(`repository/getaksbyvid/${vid}`, { method: 'GET' }, user)
            .then((data) => {
                setLoading(false);

                setItems(data);
                setVid(vid);
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onChooseItem = async (id) => {
        setState({ ...state, idIzd: id });

        setLoading(true);

        serverFetch(`repository/getaksdetals/${vid}/${id}`, { method: 'GET' }, user)
            .then((data) => {
                setLoading(false);

                setColors(data.colors);
                if (data.colors) {
                    const selectedColor = data.colors.find((item) => item.id === state.selectedColorId);
                    setState({ ...state, selectedColor: selectedColor });
                    setParams(data.paramNames);
                    setSelectedItem(id);
                }
            })
            .catch((e) => {
                setLoading(false);

                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onChooseColor = (id) => {
        if (colors) {
            const selectedColor = colors.find((item) => item.id === id);
            setState({ ...state, selectedColorId: id, selectedColor });
        }
    };

    // Определяет является ли цветовая сторона палитрой
    const getColorSideIsPallete = (selectedColor, colorSideName) => {
        if (!selectedColor) return false;

        return selectedColor && selectedColor.vid === 1 && selectedColor[colorSideName] && selectedColor[colorSideName].vid === 3;
    };

    // Выбор цвета или цветовой стороны
    const chooseSelectedColor = (id, propName) => {
        setState({ ...state, [propName]: id });
    };

    function AddCmb(dataSource, title, placeholder, selectedValue, onChoose, propName) {
        return (
            <Form.Item initialValue={selectedValue} label={title} name={propName}>
                <Select placeholder={placeholder} onChange={(value) => onChoose(value, propName)}>
                    {dataSource.map((d) => {
                        return <Option value={d.id}>{d.name}</Option>;
                    })}
                </Select>
            </Form.Item>
        );
    }

    return (
        <>
            <Modal
                visible={true}
                showTitle={true}
                title={tr('Аксессуар/Работа')}
                closeOnOutsideClick={false}
                showCloseButton={false}
                okText={tr('ОК')}
                cancelText={tr('Отмена')}
                onCancel={() => onCancel()}
                onOk={() => save()}
                cancelButtonProps={{ disabled: loading }}
                okButtonProps={{ disabled: loading }}
                width={600}
            >
                {loading && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spin size='large' tip={tr('Пожалуйста, подождите...')} />
                    </div>
                )}

                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item initialValue={vid} label={tr('Вид')} name='vid'>
                        <Select placeholder={tr('Выберите вид')} onChange={(value) => onChooseVid(value)}>
                            {kindsAks.map((d) => {
                                return (
                                    <Option key={`vid${d.id}`} value={d.id}>
                                        {d.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={selectedItem} label={tr('Материал')} name='selectedItem'>
                        <Select placeholder={tr('Выберите...')} onChange={(value) => onChooseItem(value)}>
                            {items.map((d) => {
                                return (
                                    <Option key={`vid${d.id}`} value={d.id}>
                                        {d.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    {colors && (
                        <Form.Item initialValue={state.selectedColorId} label={tr('Цвет')} name='selectedColor'>
                            <Select placeholder={tr('Выберите...')} onChange={(value) => onChooseColor(value)}>
                                {colors.map((d) => {
                                    return (
                                        <Option key={`vid${d.id}`} value={d.id}>
                                            {d.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    {getColorSideIsPallete(state.electedColor, 'colorIn') &&
                        AddCmb(
                            state.selectedColor.colorIn.colorCnt,
                            tr('Цвет изнутри'),
                            tr('Выберите цвет'),
                            state.selectedColorInId,
                            chooseSelectedColor,
                            'selectedColorInId'
                        )}
                    {getColorSideIsPallete(state.selectedColor, 'colorOut') &&
                        AddCmb(
                            state.selectedColor.colorOut.colorCnt,
                            tr('Цвет снаружи'),
                            tr('Выберите цвет'),
                            state.selectedColorOutId,
                            chooseSelectedColor,
                            'selectedColorOutId'
                        )}
                    {getColorSideIsPallete(state.selectedColor, 'colorBase') &&
                        AddCmb(
                            state.selectedColor.colorBase.colorCnt,
                            tr('Цвет основы'),
                            tr('Выберите цвет'),
                            state.selectedColorBId,
                            chooseSelectedColor,
                            'selectedColorBId'
                        )}
                    {getColorSideIsPallete(state.selectedColor, 'colorF') &&
                        AddCmb(
                            state.selectedColor.colorF.colorCnt,
                            tr('Цвет фальца'),
                            tr('Выберите цвет'),
                            state.selectedColorFId,
                            chooseSelectedColor,
                            'selectedColorFId'
                        )}
                    {tipR >= 1 && (
                        <Form.Item initialValue={state.w} label={`${tr('Длина')}, ${tr('мм')}`} name='w'>
                            <Input
                                onChange={(data) => {
                                    setState({ ...state, w: data.target.value });
                                }}
                            />
                        </Form.Item>
                    )}
                    {tipR === 2 && (
                        <Form.Item initialValue={state.h} label={`${tr('Ширина')}, ${tr('мм')}`} name='h'>
                            <Input
                                onChange={(data) => {
                                    setState({ ...state, h: data.target.value });
                                }}
                            />
                        </Form.Item>
                    )}
                    <Form.Item initialValue={state.qty} label={tr('Количество')} name='qty'>
                        <Input
                            onChange={(data) => {
                                setState({ ...state, qty: data.target.value });
                            }}
                        />
                    </Form.Item>
                    {(vid === 0 || vid === 1) && (
                        <Form.Item initialValue={state.idIzd} label={tr('Изделие')} name='idIzd'>
                            <Select placeholder={tr('Выберите...')} onChange={(value) => onChooseItem(value)}>
                                {izds.map((d) => {
                                    return (
                                        <Option key={`vid${d.id}`} value={d.id}>
                                            {d.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    {params && (
                        <>
                            <h3>{tr('Параметры')}</h3>
                            {params.map((paramName) => {
                                return (
                                    <Form.Item
                                        key={`param${paramName.id}`}
                                        name={`param${paramName.id}`}
                                        initialValue={paramValues['param' + paramName.id]}
                                        label={paramName.name}
                                    >
                                        <Select
                                            placeholder={tr('Выберите значение')}
                                            onChange={(value) => setPramValues({ ...paramValues, ['param' + paramName.id]: value })}
                                        >
                                            {paramName.values.map((d) => {
                                                return (
                                                    <Option key={`param${paramName.id}_${d.id}`} value={d.id}>
                                                        {d.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                );
                            })}
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default OrderAks;

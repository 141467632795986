import React from 'react';
import { Button } from 'antd';
import { CheckOutlined, PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';

import './button.css';
import { tr } from '../../../utils';

const MButton = ({ text, type, onClick, enabled = true, visible = true }) => {
    let icon = '';
    let btnType = 'normal';
    let btnText = text;
    switch (type) {
        case 'add':
            icon = <PlusOutlined />;
            btnType = 'primary';
            btnText = tr('Добавить');
            break;
        case 'delete':
            icon = <DeleteOutlined />;
            btnType = 'danger';
            btnText = tr('Удалить');
            break;
        case 'edit':
            icon = <EditOutlined />;
            btnType = 'default';
            btnText = tr('Изменить');
            break;
        case 'ok':
            icon = <CheckOutlined />;
            btnType = 'success';
            btnText = 'OK';
            break;
        case 'cancel':
            icon = <CloseOutlined />;
            btnType = 'danger';
            btnText = tr('Отмена');
            break;
        default:
            break;
    }
    return (
        visible && (
            <Button onClick={onClick} icon={icon} type={btnType} disabled={!enabled} className='mbutton'>
                {btnText}
            </Button>
        )
    );
};

export default MButton;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, message, Space, Avatar } from 'antd';

import NavMenu from './components/NavMenu';

import Orders from './components/order/orders';
import Order from './components/order/order';
import Packages from './components/package/packages';
import Users from './components/user-managment/users';
import Cad from './components/cad/cad';
import './custom.css';
import { userLoaded } from './store/actions';

import 'antd/dist/antd.css';
import { serverFetch } from './server';
import { Footer } from 'antd/lib/layout/layout';

const App = () => {
    const { Content } = Layout;
    const d = useDispatch();
    const user = useSelector((s) => s.user);
    const [intervalId, setIntervalId] = useState();

    useEffect(() => {
        fetch('./localization.json')
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                localStorage.setItem('localization', JSON.stringify(data));
            })
            .catch(function (err) {
                console.log(err, ' error');
            });
    }, []);

    useEffect(() => {
        intervalId && clearInterval(intervalId);

        //check is user session active
        if (user) {
            const id = setInterval(() => {
                serverFetch(`account/checksession`, { method: 'GET' }, user)
                    .then((data) => {
                        return;
                    })
                    .catch((e) => {
                        e.UserMessage && message.error(e.UserMessage);
                        d(userLoaded(null));
                        intervalId && clearInterval(intervalId);
                    });
            }, 10000);

            setIntervalId(id);
        }
    }, [user]);

    return (
        <Router>
            <Layout>
                <NavMenu />

                {user && (
                    <>
                        <Content className='content'>
                            <Switch>
                                <Route exact path='/' component={Orders} />
                                <Route path='/orders' component={Orders} />
                                <Route path='/order/edit/:orderGuid?' component={Order} />
                                <Route path='/cad/:orderGuid?/:id?' component={Cad} />
                                <Route path='/order/create' component={Order} />
                                <Route path='/packages' component={Packages} />
                                <Route path='/usermanagement' component={Users} />
                            </Switch>
                        </Content>
                        <Footer style={{ padding: '4px', textAlign: 'center' }}>
                            <Space>
                                <a href='https://itokna.net' target='_blank'>
                                    <Avatar src='/images/itokna32.png' />
                                </a>
                                <a href='https://www.youtube.com/@itokna' target='_blank'>
                                    <Avatar src='/images/youtube48.png' />
                                </a>
                                <a href='https://t.me/itokna' target='_blank'>
                                    <Avatar src='/images/telegram48.png' />
                                </a>
                            </Space>
                        </Footer>
                    </>
                )}
            </Layout>
        </Router>
    );
};

export default App;

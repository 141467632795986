import React from 'react';
import { getItemIdOrNull } from '../../../utils/utils';
import { Modal, Select, Form, message } from 'antd';

import { tr } from '../../../utils';
import { serverFetch } from '../../../server';

export const CadCmdElemInfoPopup = ({ vidOper, zapolns, selectedZapoln, onCancel, onOk, onChange }) => {
    const { Option } = Select;

    if (vidOper !== 'ELEM_INFO') return null;

    const selectedZapolnValue = getItemIdOrNull(selectedZapoln);
    return (
        <Modal
            visible={true}
            showTitle={true}
            title={tr('Выбор заполнения')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item initialValue={selectedZapolnValue} label={tr('Типовое заполнение')} name='selectedZapoln'>
                    <Select
                        placeholder={tr('Выберите типовое заполнение')}
                        onChange={(value) => {
                            onChange(zapolns.find((e) => e.id === value));
                        }}
                    >
                        {zapolns.map((d) => {
                            return (
                                <Option key={`selectedZapoln${d.id}`} value={d.id}>
                                    {d.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const CadCmdElemInfoStartOper = (idSys, onChange, user) => {
    serverFetch(`repository/getsysdetails/${idSys}`, { method: 'GET' }, user)
        .then((sys) => {
            onChange(sys.zapolns);
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
};

export async function CadCmdElemInfoDoOper(selectedObjectIds, selectedZapoln, data, user, refreshCad) {
    data = {
        ...data,
        ids: selectedObjectIds.join(),
        IdSpr: getItemIdOrNull(selectedZapoln),
    };

    await serverFetch(`izd/operchangezapoln`, { method: 'POST', bodyData: data }, user)
        .then((data) => {
            refreshCad && refreshCad();
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
}

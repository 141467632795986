import React, { useState } from 'react';
import { List, Alert, message } from 'antd';

import { MButton } from '../lib';
import { tr } from '../../utils/translate';
import { toFinanceString } from '../../extensions/utils';
import Product from './order-product';
import ProductModal from './order-product-modal';

import './order-products.css';

const Products = ({ ...props }) => {
    const { order, user, onRefresh } = props;

    const [openProduct, setOpenProduct] = useState(false);

    const cancel = () => {
        setOpenProduct(false);
    };

    const saveProduct = () => {
        setOpenProduct(false);
        onRefresh();
    };

    const deleteProduct = () => {
        onRefresh();
    };

    if (order.orderIzds === undefined) return null;

    let total = 0;
    if (order && order.orderIzds) order.orderIzds.map((s) => (total = total + s.syma));

    return (
        <>
            {order.readOnlyDescription && <Alert style={{ marginBottom: 10 }} message={order.readOnlyDescription} type='warning' />}

            <div className='izd-header'>
                <h2>{tr('Изделия')}</h2>
                <h2>
                    {tr('Итого')}: <span style={{ marginRight: 20 }}>{toFinanceString(total)}</span>
                </h2>
            </div>

            {!order.isReadOnly && (
                <MButton
                    type='add'
                    onClick={() => {
                        setOpenProduct(true);
                    }}
                />
            )}
            <List
                size='large'
                bordered
                dataSource={order.orderIzds}
                renderItem={(item) => (
                    <List.Item style={{ backgroundColor: '#ffffff' }}>
                        <Product
                            key={`constr${item.idStr}`}
                            product={item}
                            order={order}
                            user={user}
                            onDelete={deleteProduct}
                            onRefresh={onRefresh}
                        />
                    </List.Item>
                )}
            />
            {openProduct && <ProductModal orderGuid={order.orderGuid} ordno={openProduct.ordno} onCancel={cancel} onSave={saveProduct} user={user} />}
        </>
    );
};

export default Products;

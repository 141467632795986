import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import './cad-tools.css';

import { Button, message } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

import { getIzdObjects } from './cad-funcs';
import { setElemSize, setCadSize, setCadUpdated } from '../../store/actions';
import { CadCmdSplitPopup, CadCmdSplitDoOper, CadCmdSplitStartOper } from './cmd/cad-cmd-split';
import { CadCmdElemInfoPopup, CadCmdElemInfoDoOper, CadCmdElemInfoStartOper } from './cmd/cad-cmd-elem-info';
import { CadCmdAlignDoOper, CadCmdAlignStartOper } from './cmd/cad-cmd-align';
import { CadCmdMoskPopup, CadCmdMoskDoOper, CadCmdMoskStartOper } from './cmd/cad-cmd-mosk';
import { CadCmdDelDoOper, CadCmdDelStartOper } from './cmd/cad-cmd-del';
import CadCmdSetSizePopup, { CadCmdSetSizeDoOper } from './cmd/cad-cmd-set-size';
import { CadCmdStvInsPopup, CadCmdStvInsStartOper, CadCmdStvInsDoOper } from './cmd/cad-cmd-stv-ins';
import { CadCmdStvInfoPopup, CadCmdStvInfoStartOper, CadCmdStvInfoDoOper } from './cmd/cad-cmd-stv-info';
import { tr } from '../../utils';
import { serverFetch } from '../../server';

const CadTools = () => {
    const { id, orderGuid } = useParams();

    const d = useDispatch();
    const selectedObjectIds = useSelector((s) => s.selectedObjectIds);

    const izd = useSelector((s) => s.izd);
    const user = useSelector((s) => s.user);
    const elemSize = useSelector((s) => s.elemSize);
    const cadSize = useSelector((s) => s.cadSize);

    const [vidOper, setVidOper] = useState('');
    const [mosks, setMosks] = useState([]);
    const [selectedMoskValue, setSelectedMoskValue] = useState();
    const [zapolns, setZapolns] = useState([]);
    const [selectedZapoln, setSelectedZapoln] = useState();

    const [state, setState] = useState({
        //  orderGuid: new URLSearchParams(window.location.search).get('orderGuid'),
        vars: [],
        idFurn: 0,
        idFurnVar: 0,
        paramNames: [],
        furns: [],
    });

    useEffect(() => {
        getSprs();
        d(setCadSize());
    }, []);

    useEffect(() => {
        if (vidOper === 'DEL' || vidOper === 'UNDO' || vidOper === 'ALIGN_GEOM' || vidOper === 'ALIGN_LIGHT') {
            onDoOper();
            setVidOper();
        }
    }, [vidOper]);

    useEffect(() => {
        if (cadSize) {
            setVidOper('SET_SIZE');
        }
    }, [cadSize]);

    const getSprs = () => {
        serverFetch(`repository/getsprs`, { method: 'GET' }, user)
            .then((data) => {
                const { mosks } = data;
                setMosks(mosks);
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const getObjects = () => {
        const selectedObjects = getIzdObjects(izd, selectedObjectIds);
        const selectedQty = selectedObjects.length;
        const selectedObject = selectedObjects.length === 0 ? null : selectedObjects[0];
        let selectedConsIds = [];
        selectedObjects.forEach((item) => {
            if (item && selectedConsIds.indexOf(item.cons) === -1) selectedConsIds.push(item.cons);
        });
        let vidObjects = selectedQty === 0 ? 0 : selectedObject && selectedObject.vid;
        if (selectedQty > 1)
            selectedObjects.forEach((element) => {
                if (vidObjects !== element.vid) vidObjects = 0;
            });
        const selectedCons = selectedObject && izd.cadElems.find((item) => item.id === selectedObject.cons);
        const isFrame = selectedCons && selectedCons.parent === 0;
        return { selectedObjects, selectedQty, selectedObject, izd, vidObjects, selectedConsIds, isFrame };
    };

    const onCancelOper = () => {
        setVidOper();
    };

    const refreshCad = () => {
        d(setCadUpdated(true));
    };

    const onStartOper = async (vidOper) => {
        const { selectedObject } = getObjects();

        switch (vidOper) {
            case 'SPLIT_VERT':
            case 'SPLIT_HOR':
                CadCmdSplitStartOper(vidOper, selectedObject, (e) => {
                    d(setElemSize(e));
                    setVidOper(vidOper);
                });
                break;

            case 'DEL':
                CadCmdDelStartOper(() => setVidOper(vidOper));
                break;
            case 'ALIGN_GEOM':
            case 'ALIGN_LIGHT':
                CadCmdAlignStartOper(vidOper, () => setVidOper(vidOper));
                break;
            case 'ELEM_INFO':
                CadCmdElemInfoStartOper(
                    izd.idSys,
                    (e) => {
                        setZapolns(e);
                        setVidOper(vidOper);
                    },
                    user
                );
                break;
            case 'MOSK':
                CadCmdMoskStartOper(selectedObject, izd, (e) => {
                    setSelectedMoskValue(e);
                    setVidOper(vidOper);
                });
                break;
            case 'STV_INS':
                CadCmdStvInsStartOper(
                    izd,
                    (e) => {
                        setState({ ...state, ...e });
                        setVidOper(vidOper);
                    },
                    user
                );
                break;
            case 'STV_INFO':
                await CadCmdStvInfoStartOper(
                    selectedObject,
                    izd,
                    (e) => {
                        setState({ ...state, ...e });
                        setVidOper(vidOper);
                    },
                    user
                );
                break;
            case 'UNDO':
                if (window.confirm(tr('Отменить действие?'))) {
                    setVidOper(vidOper);
                }
                break;

            case 'SHOW_ERROR':
                let errors = [];
                izd.cadErrors.forEach((item) => {
                    if (errors.indexOf(item.text) === -1) errors.push(item.text);
                });
                message.error(errors + '');
                break;
            default:
                break;
        }
    };

    async function onDoOper() {
        const { selectedObject, selectedConsIds } = getObjects();

        let data = { orderGuid: orderGuid, idStr: parseInt(id) };

        if (selectedObject) {
            data = { ...data, id: selectedObject.id };
        }

        switch (vidOper) {
            case 'SPLIT_VERT':
            case 'SPLIT_HOR':
                CadCmdSplitDoOper(selectedObject, vidOper, elemSize && elemSize.xValue, data, user, () => refreshCad());
                break;
            case 'DEL':
                CadCmdDelDoOper(selectedObject, data, user, () => refreshCad());
                break;
            case 'ALIGN_GEOM':
            case 'ALIGN_LIGHT':
                CadCmdAlignDoOper(selectedObjectIds, vidOper, data, user, () => refreshCad());
                break;
            case 'ELEM_INFO':
                CadCmdElemInfoDoOper(selectedObjectIds, selectedZapoln, data, user, () => refreshCad());
                break;
            case 'MOSK':
                CadCmdMoskDoOper(selectedConsIds, selectedMoskValue, data, user, () => refreshCad());
                break;
            case 'STV_INS':
                CadCmdStvInsDoOper(selectedObjectIds, state, data, user, () => refreshCad());
                break;
            case 'STV_INFO':
                CadCmdStvInfoDoOper(selectedObject, izd, state, data, user, () => refreshCad());
                break;
            case 'SET_SIZE':
                CadCmdSetSizeDoOper(selectedObject, cadSize, data, user, () => refreshCad());
                break;
            case 'UNDO':
                await serverFetch(`izd/operundo`, { method: 'POST', bodyData: data }, user)
                    .then((data) => {
                        refreshCad();
                    })
                    .catch((e) => {
                        e.UserMessage && message.error(e.UserMessage);
                    });
                break;

            default:
                return;
        }

        setVidOper();
    }

    const onChangeSize = (name, value) => {
        d(setElemSize({ [name]: value }));
    };

    const getButton = (icon, action, vidOper) => {
        const { selectedObject, selectedQty, izd, vidObjects, isFrame } = getObjects();

        let disabled = false;
        switch (vidOper) {
            case 'SPLIT_VERT':
            case 'SPLIT_HOR':
                disabled = selectedQty !== 1 || !selectedObject || selectedObject.vid !== 3;
                break;
            case 'DEL':
                disabled = selectedQty !== 1 || !selectedObject || selectedObject.vid === 1;
                break;
            case 'UNDO':
                disabled = !izd.hasUndo;
                break;
            case 'ALIGN_GEOM':
            case 'ALIGN_LIGHT':
                disabled = selectedQty < 2 || vidObjects !== 3;
                break;
            case 'SHOW_ERROR':
                disabled = izd.cadErrors.length === 0;
                break;
            case 'ELEM_INFO':
            case 'STV_INS':
            case 'STV_INFO':
                disabled = selectedQty === 0 || vidObjects !== 3;
                break;
            case 'MOSK':
                disabled = selectedQty === 0 || vidObjects !== 3 || isFrame;
                break;
            case 'CHANGE':
                disabled = false;
                break;
            case 'AKS_INS':
                disabled = selectedQty === 0 || vidObjects !== 2;
                break;
            default:
                disabled = true;
        }
        return (
            <Button
                disabled={disabled}
                icon={<img src={icon} style={{ marginBottom: 4 }} />}
                onClick={() => {
                    action(vidOper);
                }}
                id={vidOper}
                block
                size='large'
                style={{
                    backgroundColor: !disabled && '#ffffff',
                    filter: disabled && 'grayscale(100%)',
                }}
            />
        );
    };
    return (
        <>
            <div style={{ position: 'absolute', width: 40 }}>
                <Button
                    icon={<RollbackOutlined />}
                    onClick={() => window.open(`/order/edit/${orderGuid}`, '_self')}
                    block
                    size='large'
                    style={{ marginBottom: 20 }}
                />
                {getButton('images/icons/cad-split-vert.png', onStartOper, 'SPLIT_VERT')}
                {getButton('images/icons/cad-split-hor.png', onStartOper, 'SPLIT_HOR')}
                {getButton('images/icons/cad-del.png', onStartOper, 'DEL')}
                {getButton('images/icons/cad-allign-geom.png', onStartOper, 'ALIGN_GEOM')}
                {getButton('images/icons/cad-allign-ligth.png', onStartOper, 'ALIGN_LIGHT')}
                {getButton('images/icons/cad-undo.png', onStartOper, 'UNDO')}
                {getButton('images/icons/cad-elem-info.png', onStartOper, 'ELEM_INFO')}
                {getButton('images/icons/cad-mosk.png', onStartOper, 'MOSK')}
                {getButton('images/icons/cad-stv-ins.png', onStartOper, 'STV_INS')}
                {getButton('images/icons/cad-stv-info.png', onStartOper, 'STV_INFO')}
                {/*{getButton("images/icons/cad-sys-change.png", this.onStartOper, "CHANGE")}*/}
                {/*{getButton("images/icons/cad-aks-ins.png", this.onStartOper, "AKS_INS")}*/}
                {getButton('images/icons/cad-error.png', onStartOper, 'SHOW_ERROR')}
            </div>
            <CadCmdSplitPopup
                vidOper={vidOper}
                onCancel={() => onCancelOper()}
                onOk={() => onDoOper()}
                onChange={(name, value) => onChangeSize(name, value)}
            />
            <CadCmdElemInfoPopup
                vidOper={vidOper}
                zapolns={zapolns}
                selectedZapoln={selectedZapoln}
                onCancel={() => onCancelOper()}
                onOk={() => onDoOper()}
                onChange={(e) => setSelectedZapoln(e)}
            />
            <CadCmdMoskPopup
                vidOper={vidOper}
                mosks={mosks}
                selectedMoskValue={selectedMoskValue}
                onCancel={() => onCancelOper()}
                onOk={() => onDoOper()}
                onChange={(e) => setSelectedMoskValue(e)}
            />
            <CadCmdStvInsPopup
                vidOper={vidOper}
                izd={izd}
                state={state}
                user={user}
                onChange={(e) => {
                    setState({ ...state, ...e });
                }}
                onCancel={() => onCancelOper()}
                onOk={() => onDoOper()}
            />
            <CadCmdStvInfoPopup
                vidOper={vidOper}
                izd={izd}
                state={state}
                user={user}
                onChange={(e) => {
                    setState({ ...state, ...e });
                }}
                onCancel={() => onCancelOper()}
                onOk={() => onDoOper()}
            />
            <CadCmdSetSizePopup vidOper={vidOper} cadSize={cadSize} onCancel={() => onCancelOper()} onOk={() => onDoOper()} />
        </>
    );
};

export default CadTools;

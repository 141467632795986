import React from 'react';
import { Modal, Select, Form, message } from 'antd';
import { tr } from '../../../utils';
import { serverFetch } from '../../../server';

export const CadCmdStvInsPopup = ({ vidOper, izd, onCancel, onOk, onChange, state, user }) => {
    if (vidOper !== 'STV_INS') return null;

    if (izd === null || izd === undefined) return null;

    const { Option } = Select;

    const { idSys } = izd;
    const { idFurn, furns, vars, paramNames } = state;

    const title = tr('Вставка створки');

    return (
        <Modal
            visible={true}
            showTitle={true}
            title={title}
            closeOnOutsideClick={false}
            showCloseButton={false}
            okText={tr('ОК')}
            cancelText={tr('Отмена')}
            onCancel={() => onCancel()}
            onOk={() => onOk()}
            width={500}
        >
            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                {furns && (
                    <>
                        <h2>{tr('Фурнитура')}</h2>
                        <Form.Item label={tr('Вид фурнитуры')} name='idFurn'>
                            <Select
                                placeholder={tr('Выберите вид фурнитуры')}
                                onChange={(value) =>
                                    serverFetch(`repository/getsysfurnvars/${idSys}/${value}`, { method: 'GET' }, user)
                                        .then((vars) => {
                                            onChange({
                                                loading: false,
                                                vars,
                                                idFurn: value,
                                            });
                                        })
                                        .catch((e) => {
                                            e.UserMessage && message.error(e.UserMessage);;
                                        })
                                }
                            >
                                {furns.map((d) => {
                                    return (
                                        <Option key={`idFurn${d.id}`} value={d.id}>
                                            {d.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        {vars && (
                            <Form.Item label={tr('Вариант')} name='idFurnVar'>
                                <Select
                                    placeholder={tr('Выберите вариант')}
                                    onChange={(value) =>
                                        serverFetch(`repository/getsysfurnvardetails/${idSys}/${idFurn}/${value}`, { method: 'GET' }, user)
                                            .then((data) => {
                                                if (data === null) return;
                                                let x = {};
                                                data.paramValues.forEach((item) => {
                                                    x['param' + item.idName] = item.idValue;
                                                });
                                                onChange({
                                                    loading: false,
                                                    idFurnVar: value,
                                                    paramNames: data.paramNames,
                                                    ...x,
                                                });
                                            })
                                            .catch((e) => {
                                                e.UserMessage && message.error(e.UserMessage);;
                                            })
                                    }
                                >
                                    {vars.map((d) => {
                                        return (
                                            <Option key={`idFurnVar${d.id}`} value={d.id}>
                                                {d.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        )}
                    </>
                )}

                {paramNames && (
                    <>
                        <h2>{tr('Параметры')}</h2>
                        {paramNames.map((paramName) => {
                            return (
                                <Form.Item
                                    key={`param${paramName.id}`}
                                    initialValue={state['param' + paramName.id]}
                                    label={paramName.name}
                                    name={'param' + paramName.id}
                                >
                                    <Select
                                        placeholder={tr('Выберите значение')}
                                        onChange={(value) => {
                                            onChange({ ['param' + paramName.id]: value });
                                        }}
                                    >
                                        {paramName.values.map((d) => {
                                            return (
                                                <Option key={`paramNames${d.id}`} value={d.id}>
                                                    {d.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            );
                        })}
                    </>
                )}
            </Form>
        </Modal>
    );
};

export const CadCmdStvInsStartOper = (izd, onChange, user) => {
    if (izd !== null) {
        const { idSys } = izd;
        serverFetch(`repository/getsysfurns/${idSys}`, { method: 'GET' }, user)
            .then((furns) => {
                onChange({ furns: furns });
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);;
            });
    }
};

export async function CadCmdStvInsDoOper(selectedObjectIds, state, data, user, refreshCad) {
    const { idFurn, idFurnVar, paramNames } = state;

    let params = [];
    paramNames &&
        paramNames.forEach((pn) =>
            params.push({
                IdName: pn.id,
                IdVal: state['param' + pn.id],
            })
        );

    data = {
        ...data,
        ids: selectedObjectIds.join(),
        idFurn: idFurn,
        idFurnVar: idFurnVar,
        sParam: JSON.stringify(params),
    };

    await serverFetch(`izd/operaddsash`, { method: 'POST', bodyData: data }, user)
        .then((data) => {
            refreshCad && refreshCad();
        })
        .catch((e) => {
            e.UserMessage && message.error(e.UserMessage);;
        });
}

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Table, Space, Button, message } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';

import { serverFetch } from '../../server';

import { permission, hasPermission } from '../../extensions/helper';
import { tr } from '../../utils/translate';
import User from './user';

const Users = () => {
    console.log('Users');
    const user = useSelector((s) => s.user);

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedOrderIds, setSelectedUserIds] = useState([]);
    const [currentUser, setCurrentUser] = useState();
    const [editMode, setEditMode] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState(true);

    useEffect(() => {
        if (refreshRequired) {
            setRefreshRequired(false);
            getData();
        }
    }, [refreshRequired]);

    const getData = () => {
        if (!hasPermission(user.permissions, permission.CanManageUsers)) {
            window.open('/', '_self');
            return;
        }

        setLoading(true);

        serverFetch(`usermanagement`, { method: 'GET' }, user)
            .then((data) => {
                setUsers(data);
                setLoading(false);
            })
            .catch((e) => {
                message.error(
                    <>
                        {tr('Ошибка получения пользователей')}
                        <p>- {e.UserMessage}</p>
                    </>
                );

                setLoading(false);
            });
    };

    const renderToolbar = () => {
        return (
            <Space className='toolbal'>
                <Button
                    icon={<ReloadOutlined />}
                    onClick={() => {
                        setRefreshRequired(true);
                    }}
                >
                    {tr('Обновить')}
                </Button>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setSelectedUserIds([]);
                        setCurrentUser();
                        setEditMode(true);
                    }}
                >
                    {tr('Добавить')}
                </Button>
                <Button
                    disabled={!currentUser}
                    icon={<EditOutlined />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    {tr('Изменить')}
                </Button>
                <Button
                    disabled={!currentUser}
                    type='primary'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        const message = `Вы уверены что хотите удалить пользователя "${currentUser && currentUser.userName}"?`;

                        if (window.confirm(message)) {
                            serverFetch(`usermanagement/${currentUser.id}`, { method: 'DELETE' }, user)
                                .then(() => {
                                    setCurrentUser();
                                    setRefreshRequired(true);
                                })
                                .catch((e) => {
                                    e.UserMessage && message.error(e.UserMessage);
                                });
                        }
                    }}
                >
                    {tr('Удалить')}
                </Button>
            </Space>
        );
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedUserIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = users.find((o) => o.id == selectedRowKeys[0]);
            setCurrentUser(entity);
        } else {
            setCurrentUser();
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedOrderIds,
        onChange: onSelectChange,
        type: 'radio',
    };

    const getColumns = () => {
        return [
            {
                title: tr('Имя пользователя'),
                dataIndex: 'userName',
                width: 200,
            },
            {
                title: tr('Должность'),
                dataIndex: 'position',
                width: 200,
            },
            {
                title: tr('ФИО'),
                dataIndex: 'fullName',
                width: 240,
            },
        ];
    };
    const renderTable = () => {
        const columns = getColumns();

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={users}
                loading={loading}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id]);
                        },
                    };
                }}
                scroll={{ y: 'calc(100vh - 251px)', x: 'calc(100vw - 40px)' }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 20,
                }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {editMode && (
                <User
                    id={currentUser ? currentUser.id : undefined}
                    onCancel={() => {
                        setEditMode(false);
                    }}
                    onSave={() => {
                        setEditMode(false);
                        setRefreshRequired(true);
                    }}
                />
            )}
        </>
    );
};

export default Users;

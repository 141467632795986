export const permission = {
    CanManageUsers: 1,
    CanLoadSettings: 4,
    AddOrderToPackage: 23,
    ViewPackages: 84,
};

export function hasPermission(permissions, permission) {
    if (!permissions) return false;

    let value = permissions[permission - 1];
    return value == 1;
}

export const getPermissionLabel = (code) => {
    switch (code) {
        // --------------- CR PERMISSIONS ---------------
        case permission.CanManageUsers:
            return 'Can Manage Users';
        case permission.CanLoadSettings:
            return 'Can Load Settings';
        case permission.AddOrderToPackage:
            return 'Add Order To Package';
        case permission.ViewPackages:
            return 'View Packages';
        default:
            return '';
    }
};

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Menu, Input, Form, Modal, Space, Button, message } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { userLoaded } from '../store/actions';

import { tr } from '../utils';
import { serverFetch } from '../server';

const Login = () => {
    const d = useDispatch();

    const [form] = Form.useForm();

    const [state, setState] = useState({
        userName: '',
        password: '',
    });

    const [loading, setLoading] = useState(false);
    const [visibleForm, setVisibleForm] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            setLoading(false);
        };
    }, []);

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            form.submit();
        }
    };

    const onSignIn = () => {
        form.validateFields().then(() => {
            setLoading(true);

            serverFetch(`account/login/${state.username}/${state.password}`, { method: 'GET' })
                .then((data) => {
                    if (data) {
                        d(userLoaded(data));
                    } else {
                        message.error(tr('Ошибка авторизации'));
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error(e.UserMessage);
                });
        });
    };

    const onChange = (data) => {
        setState({ ...state, [data.target.name]: data.target.value });
    };

    return (
        <>
            <Menu.Item key='orders' icon={<LoginOutlined />}>
                <Link to='' onClick={() => setVisibleForm(true)}>
                    {tr('Вход')}
                </Link>
            </Menu.Item>

            <Modal
                forceRender
                //visible={visibleForm}
                visible={true}
                showTitle={true}
                title={tr('Вход')}
                closeOnOutsideClick={false}
                footer={null}
                getContainer={false}
                width={400}
                onCancel={() => setVisibleForm(false)}
            >
                <Form colon={false} form={form} initialValues={{ remember: true }}>
                    <Form.Item name='username' rules={[{ required: true, message: 'Пожалуйста введите имя пользователя' }]}>
                        <Input
                            name='username'
                            placeholder={tr('Имя пользователя')}
                            value={state.username}
                            onChange={onChange}
                            disabled={loading}
                        />
                    </Form.Item>
                    <Form.Item name='password'>
                        <Input.Password
                            name='password'
                            placeholder={tr('Пароль')}
                            value={state.password}
                            onChange={onChange}
                            disabled={loading}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space style={{ marginBottom: 0, float: 'right' }}>
                            <Button onClick={() => setVisibleForm(false)}>Отмена</Button>
                            <Button type='primary' htmlType='submit' onClick={onSignIn} loading={loading}>
                                OK
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Login;

const orderLoaded = (order) => {
    return {
        type: 'ORDER_LOADED',
        payload: order,
    };
};

const userLoaded = (user) => {
    return {
        type: 'USER_LOADED',
        payload: user,
    };
};

const izdLoaded = (newIzd) => {
    return {
        type: 'IZD_LOADED',
        payload: newIzd,
    };
};

const izdSelected = (selectedObjectIds) => {
    return {
        type: 'IZD_SELECTED',
        payload: selectedObjectIds,
    };
};

const setElemSize = (payload) => {
    return {
        type: 'SET_ELEM_SIZE',
        payload: payload,
    };
};

const setCadSize = (payload) => {
    return {
        type: 'SET_CAD_SIZE',
        payload: payload,
    };
};

const setCadUpdated = (payload) => {
    return {
        type: 'CAD_UPDATED',
        payload: payload,
    };
};

export { orderLoaded, userLoaded, izdLoaded, izdSelected, setElemSize, setCadSize, setCadUpdated };
